import { useEffect, useMemo, useState, useRef } from "react";
import { Stack, IconButton } from "@fluentui/react";
import DOMPurify from "dompurify";
import styles from "./Answer.module.scss";
import { encrypt, AskResponse, ChatCommentResponse, decryptData, fetchLoginCookie, getChatComments, getCitationFilePath, getOneAgent, getOneAgentForMagicLink } from "../../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import { Dispatch, SetStateAction } from 'react'
import clsx from 'clsx'
import SortIcon from '@mui/icons-material/Sort';
import QueueIcon from '@mui/icons-material/Queue';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AddIcon from '@mui/icons-material/Add';
import { scrapeWebsite } from "./GetWebMetadata";
import pdf from '../../../media_dsd/pdf-icon.png';
import text from '../../../media_dsd/text-x-scala-icon.png';
import "react-toastify/dist/ReactToastify.css"; // Import CSS styles
import axiosInstance from "axios";
import CheckIcon from '@mui/icons-material/Check';
import word from '../../../media_dsd/word.png';
import xlsx from '../../../media_dsd/microsoft-excel-icon.png';
import ppt from '../../../media_dsd/microsoft-powerpoint-icon.png';
import json from '../../../media_dsd/json-file.png';
import html from '../../../media_dsd/html-icon.png';
import csv from '../../../media_dsd/csv.png';
import zip from '../../../media_dsd/zip-folder.png';
import file from '../../../media_dsd/new-doc.png';
import odt from '../../../media_dsd/odt-file.png';
import ods from '../../../media_dsd/ods-file.png';
import odp from '../../../media_dsd/odp-file.png';
import xml from '../../../media_dsd/xml.png';
import rtf from '../../../media_dsd/rtf.png';
import eml from '../../../media_dsd/eml.png';
import epub from '../../../media_dsd/epub.png';
import { useLocation } from 'react-router-dom';
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from 'rehype-raw'
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneLight } from "react-syntax-highlighter/dist/esm/styles/prism";
import Feedbacks from "./Feedback";
import { Lightbulb, Files, ArrowCounterClockwise, ThumbsUp, ThumbsDown, ChatsCircle, Clipboard } from "@phosphor-icons/react";
import { useParams } from "react-router-dom";
import 'katex/dist/katex.min.css';
// import remarkMath from 'remark-math';
// import rehypeKatex from 'rehype-katex';
import { BlockMath } from 'react-katex';
interface Props {
    AgentName: string | undefined
    answer: AskResponse;
    isSelected?: boolean;
    onCitationClicked: (filePath: string) => void;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
    setOpenModal: Dispatch<SetStateAction<boolean>>;
    onThoughtProcessClicked: () => void;
    onRegenerateClicked: () => Promise<void>;
    onSupportingContentClicked: () => void;
    showRegenerate: boolean;
    last: boolean;
    onUpvoteResponseClicked: () => Promise<void>;
    onDownvoteResponseClicked: () => Promise<void>;
    is_upvote: boolean;
    is_downvote: boolean;
    feedbackComments: ChatCommentResponse[];
    chat_id: number;
    linkClicked: boolean;
    setLinkClicked: Dispatch<SetStateAction<boolean>>;

}

export const Answer = ({
    last,
    showRegenerate,
    setOpenModal,
    AgentName,
    answer,
    isSelected,
    onCitationClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onRegenerateClicked,
    onUpvoteResponseClicked,
    onDownvoteResponseClicked,
    is_downvote,
    is_upvote,
    feedbackComments,
    chat_id,
    linkClicked,
    setLinkClicked,
}: Props) => {
    const { agentId } = useParams()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const shareQueryParam = decodeURIComponent(decryptData(queryParams.get('share')))
    const parsedAnswer = useMemo(() => parseAnswerToHtml(answer.references, answer.answer, answer.indexes, answer.sources, answer.followup_questions, onCitationClicked), [answer]);

    const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);
    const userInfo = fetchLoginCookie();
    const decryptedRes = decryptData(userInfo);
    const isSuperUser = decryptedRes?.is_superuser?.toLowerCase() === "true";
    const userEmail = decryptedRes?.user;
    const activeTheme = localStorage.getItem("kt_theme_mode_value");
    const [isHovered, setIsHovered] = useState(false);
    // const sources = answer.references.map(reference => Object.values(reference)[0]);
    const [titles, setTitles] = useState<string[]>([]); // State variable for titles
    const [favicon, setfavicon] = useState<string[]>([]); // State variable for favicon
    const [siteName, setSiteName] = useState<string[]>([])
    const [filepath, setfilepath] = useState<string[]>([])
    const [parsedTextt, setParsedText] = useState<string>('')
    const [hoverStates, setHoverStates] = useState(
        Array.from({ length: parsedAnswer.followupQuestions.length }, () => ({
            isHovered: false,
        }))
    );
    const [isHoveredS, setIsHoveredS] = useState(new Array(titles.length).fill(false));
    const [isHoveredSC, setIsHoveredSC] = useState(false);
    const [isHoveredRG, setIsHoveredRG] = useState(false);
    const [isHoveredTU, setIsHoveredTU] = useState(false);
    const [isHoveredTD, setIsHoveredTD] = useState(false);
    const [isHoveredC, setIsHoveredC] = useState(false);
    const [copied, setCopied] = useState(false);
    const [showCopiedText, setShowCopiedText] = useState(false);
    const answerTextRef = useRef<HTMLDivElement | null>(null); // Specify the type of answerTextRef
    const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false);
    const [comments, setComments] = useState<ChatCommentResponse[]>(feedbackComments)
    const [agentType, setAgentType] = useState<string>('')

    const fetchAgentInfo = async (agentId) => {
        try {
            if (agentId) {
                const agentID = decodeURIComponent(decryptData(agentId));
                const parsedAgentID = parseInt(agentID, 10);
                let response;
                if (shareQueryParam === 'Allow-Edit-Only' || shareQueryParam === 'Allow-View-Only') {
                    response = await getOneAgentForMagicLink(parsedAgentID);
                }
                else {
                    response = await getOneAgent(parsedAgentID);
                }
                if (response) {
                    setAgentType(response.agent_type.name)
                }
            }
        } catch (error) {
            console.error(error);

        }
    };

    useEffect(() => {
        fetchAgentInfo(agentId)
    }, [])


    const handleCitationDownload = (filePath: string) => {
        if (filePath && filePath.length >= 35 && filePath.length <= 60 && filePath.includes('-') && filePath.split('-').length === 6) {
            window.open(filePath, '_blank');
        } else {
            window.open(filePath, '_blank');
        }
    }
    (window as any).handleCitationDownload = (filePath: string) => {
        if (filePath && filePath.length >= 35 && filePath.length <= 60 && filePath.includes('-') && filePath.split('-').length === 6) {
            window.open(filePath, '_blank');
        } else {
            window.open(filePath, '_blank');
        }
    };

    useEffect(() => {
        // Clean up: remove the function from the window object when the component unmounts
        return () => {
            delete (window as any).handleCitationDownload;
        };
    }, []);

    function parseText(text: string) {
        let result = '';
        let counter = 0;
        let replacedString = text;
        if (agentType === 'Tutor') {
            const pattern = /\\\\\[([\s\S]*?)\\\\\]|\\\[([^]*?)\\\]|\\\(([^]*?)\\\)|\n\n\\\\text\(([^]*?)\)\n\n|\\\\text\(([^]*?)\)|\\\((.*?)\\\)/g;
            replacedString = text.replace(pattern, (match, p1, p2, p3, p4, p5, p6) => {
                const cleanedMatch = (p1 || p2 || p3 || p4 || p5 || p6).replace(/\\\\/g, '\\');
                return `<ul id='mathEquation'>${cleanedMatch}</ul>`;
            });
        }
        // Define the regex pattern to match the desired format [number]
        const pattern = /\[(\d+)\]/g;
        // Use replace method with a callback function to process the matched patterns
        result = replacedString.replace(pattern, (match, p1, offset, str) => {
            const processedPattern = processPattern(p1, counter);
            return processedPattern;
        });

        return result;
    }


    function processPattern(pattern: string, counter: number) {

        const part = pattern.substring(1, pattern.length - 1);


        const referenceItem = answer.references.find((x) => parseInt(part) == x.number);

        if (referenceItem) {
            const agentID = decodeURIComponent(decryptData(agentId));
            const parsedAgentID = parseInt(agentID, 10);

            const path = getCitationFilePath(referenceItem.url);
            if (referenceItem.url.includes('https://')) {

                counter++
                return `<a href="${referenceItem.url}" target="_blank"><sup>${referenceItem.order}</sup></a>`;
            }
            else {
                counter++

                return `<a onClick="handleCitationDownload('${path}?agentId=${encrypt(parsedAgentID.toString())}&email=${encrypt(userEmail)}')"><sup>${referenceItem.order}</sup></a>`;
            }

        }

        return ``;
    }
    useEffect(() => {
        const originalText = sanitizedAnswerHtml
        const parsedText = parseText(originalText);
        setParsedText(parsedText)

    }, [answer, agentType])

    function isYouTubeLink(url: string) {
        // Regular expression pattern to match YouTube URLs
        const youtubePattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+/;
        return youtubePattern.test(url);
    }
    useEffect(() => {
        setTitles([])
        setfavicon([])
        setSiteName([])
        setfilepath([])
        processUrls();
    }, [answer])

    async function processUrls() {

        for (const item of answer.references) {

            const url = item.url;

            if (url.includes('https://') && !(url.endsWith('.pdf'))) {
                if (isYouTubeLink(url)) {
                    // It's a valid YouTube link with "https://", so you can run GetMetaData function here

                    const requestUrl = `https://youtube.com/oembed?url=${url}&format=json`;
                    try {
                        const result = await axiosInstance.get(requestUrl); // Use the new import name
                        // Extract the title from the metadata
                        const title = result.data.title || " ";
                        setTitles((prevTitles) => [...prevTitles, title]);
                        // Extract the favicon from the metadata
                        const favicon = result.data.thumbnail_url || " ";
                        setfavicon((prevfavicon) => [...prevfavicon, favicon]);
                        const siteName = result.data.author_name || " "
                        setSiteName((prevSiteName) => [...prevSiteName, siteName]);
                        const filepath = 'url'
                        setfilepath((prevPath) => [...prevPath, filepath]);

                    } catch (error) {
                        console.error('Error:', error);
                    }
                } else {

                    try {
                        const data = await scrapeWebsite(url);
                        if (data) {

                            const title = data.title || " "; // Provide a default if title is not available
                            setTitles((prevTitles) => [...prevTitles, title]);
                            // Extract the favicon from the metadata
                            const favicon = data.faviconUrl || " "; // Provide a default if title is not available
                            setfavicon((prevfavicon) => [...prevfavicon, favicon]);
                            const siteName = data.siteName || " "
                            setSiteName((prevSiteName) => [...prevSiteName, siteName]);
                            const filepath = 'url'
                            setfilepath((prevPath) => [...prevPath, filepath]);


                        }
                    } catch (error) {
                        console.error('Error:', error);
                    }
                }
            } else {
                // the url is of pdf or text
                isFile(item)

            }
        }
    }

    const isFile = (x: { order: number; url: string; number: number; }) => {
        const agentID = decodeURIComponent(decryptData(agentId));
        const parsedAgentID = parseInt(agentID, 10);

        const path = getCitationFilePath(x.url);
        let title = "";
        let favicon = "";
        let siteName = "";
        let filepath = "";

        switch (true) {

            case path.endsWith('.pdf'):
                title = ` ${x.url}` || " ";
                favicon = pdf || " ";
                siteName = 'PDF' || " ";
                filepath = `${path}?agentId=${encrypt(parsedAgentID.toString())}&email=${encrypt(userEmail)}` || "";
                break;
            case path.endsWith('.txt'):

                const name = x.url.slice(0, -4)
                if (name.endsWith('=')) {
                    title = x.order <= 9 ? `Email-0${x.order}` : `Email-${x.order}` || " ";
                } else if (x.url && x.url.length == 40 && x.url.includes('-') && x.url.split('-').length === 5) {

                    title = x.order <= 9 ? `Text-0${x.order}` : `Text-${x.order}` || " ";
                } else {
                    title = ` ${x.url}` || " ";
                }
                favicon = text || " ";
                siteName = 'Text' || " ";
                filepath = `${path}?agentId=${encrypt(parsedAgentID.toString())}&email=${encrypt(userEmail)}` || "";
                break;
            case path.endsWith('.xlsx') || path.endsWith('.xls') || path.endsWith('.xlsm'):
                title = ` ${x.url}` || " ";
                favicon = xlsx || " ";
                siteName = 'Excel' || " ";
                filepath = `${path}?agentId=${encrypt(parsedAgentID.toString())}&email=${encrypt(userEmail)}` || "";
                break;
            case path.endsWith('.docx') || path.endsWith('.doc') || path.endsWith('.docm'):
                title = ` ${x.url}` || " ";
                favicon = word || " ";
                siteName = 'Word' || " ";
                filepath = `${path}?agentId=${encrypt(parsedAgentID.toString())}&email=${encrypt(userEmail)}` || "";
                break;
            case path.endsWith('.ppt') || path.endsWith('.pptx') || path.endsWith('.pptm'):
                title = ` ${x.url}` || " ";
                favicon = ppt || " ";
                siteName = 'PPT' || " ";
                filepath = `${path}?agentId=${encrypt(parsedAgentID.toString())}&email=${encrypt(userEmail)}` || "";
                break;
            case path.endsWith('.csv'):
                title = ` ${x.url}` || " ";
                favicon = csv || " ";
                siteName = 'CSV' || " ";
                filepath = `${path}?agentId=${encrypt(parsedAgentID.toString())}&email=${encrypt(userEmail)}` || "";
                break;
            case path.endsWith('.html'):
                title = ` ${x.url}` || " ";
                favicon = html || " ";
                siteName = 'HTML' || " ";
                filepath = `${path}?agentId=${encrypt(parsedAgentID.toString())}&email=${encrypt(userEmail)}` || "";
                break;
            case path.endsWith('.json'):
                title = ` ${x.url}` || " ";
                favicon = json || " ";
                siteName = 'JSON' || " ";
                filepath = `${path}?agentId=${encrypt(parsedAgentID.toString())}&email=${encrypt(userEmail)}` || "";
                break;
            case path.endsWith('.zip'):
                title = ` ${x.url}` || " ";
                favicon = zip || " ";
                siteName = 'ZIP' || " ";
                filepath = `${path}?agentId=${encrypt(parsedAgentID.toString())}&email=${encrypt(userEmail)}` || "";
                break;
            case path.endsWith('.eml'):
                title = ` ${x.url}` || " ";
                favicon = eml || " ";
                siteName = 'EML' || " ";
                filepath = `${path}?agentId=${encrypt(parsedAgentID.toString())}&email=${encrypt(userEmail)}` || "";
                break;
            case path.endsWith('.epub'):
                title = ` ${x.url}` || " ";
                favicon = epub || " ";
                siteName = 'EPUB' || " ";
                filepath = `${path}?agentId=${encrypt(parsedAgentID.toString())}&email=${encrypt(userEmail)}` || "";
                break;
            case path.endsWith('.xml'):
                title = ` ${x.url}` || " ";
                favicon = xml || " ";
                siteName = 'XML' || " ";
                filepath = `${path}?agentId=${encrypt(parsedAgentID.toString())}&email=${encrypt(userEmail)}` || "";
                break;
            case path.endsWith('.gz'):
                title = ` ${x.url}` || " ";
                favicon = zip || " ";
                siteName = 'GZ' || " ";
                filepath = `${path}?agentId=${encrypt(parsedAgentID.toString())}&email=${encrypt(userEmail)}` || "";
                break;
            case path.endsWith('.rtf'):
                title = ` ${x.url}` || " ";
                favicon = rtf || " ";
                siteName = 'RTF' || " ";
                filepath = `${path}?agentId=${encrypt(parsedAgentID.toString())}&email=${encrypt(userEmail)}` || "";
                break;
            case path.endsWith('.odt'):
                title = ` ${x.url}` || " ";
                favicon = odt || " ";
                siteName = 'ODT' || " ";
                filepath = `${path}?agentId=${encrypt(parsedAgentID.toString())}&email=${encrypt(userEmail)}` || "";
                break;
            case path.endsWith('.ods'):
                title = ` ${x.url}` || " ";
                favicon = ods || " ";
                siteName = 'ODS' || " ";
                filepath = `${path}?agentId=${encrypt(parsedAgentID.toString())}&email=${encrypt(userEmail)}` || "";
                break;
            case path.endsWith('.odp'):
                title = ` ${x.url}` || " ";
                favicon = odp || " ";
                siteName = 'ODP' || " ";
                filepath = `${path}?agentId=${encrypt(parsedAgentID.toString())}&email=${encrypt(userEmail)}` || "";
                break;
            case path && path.length >= 35 && path.length <= 60 && path.includes('-') && path.split('-').length === 6:
                title = ` Text-0${x.order}` || " ";
                favicon = text || " ";
                siteName = 'Text' || " ";
                filepath = `${path}?agentId=${encrypt(parsedAgentID.toString())}&email=${encrypt(userEmail)}` || "";
                break;
            default:
                title = ` ${x.url}` || " ";
                favicon = file || " ";
                siteName = 'File' || " ";
                filepath = `${path}?agentId=${encrypt(parsedAgentID.toString())}&email=${encrypt(userEmail)}` || "";
        }

        setTitles((prevTitles) => [...prevTitles, title]);
        setfavicon((prevfavicon) => [...prevfavicon, favicon]);
        setSiteName((prevSiteName) => [...prevSiteName, siteName]);
        setfilepath((prevPath) => [...prevPath, `${path}?agentId=${encrypt(parsedAgentID.toString())}&email=${encrypt(userEmail)}` || ""]);
    }
    const handleMouseEnter = (index: number) => {
        const newHoveredStates = [...isHoveredS];
        newHoveredStates[index] = true;
        setIsHoveredS(newHoveredStates);
    };

    const handleMouseLeave = (index: number) => {
        const newHoveredStates = [...isHoveredS];
        newHoveredStates[index] = false;
        setIsHoveredS(newHoveredStates);
    };
    function removeLeadingNumber(text: string) {
        // Use a regular expression to remove leading numbers/periods and surrounding quotes
        return text.replace(/^["']?\d+\.\s*/, '');
    }

    const handleCopyAnswerText = () => {
        const answerTextElement = answerTextRef.current;
        if (answerTextElement) {
            const range = document.createRange();
            range.selectNodeContents(answerTextElement);
            const selection = window.getSelection();
            if (selection) {
                selection.removeAllRanges();
                selection.addRange(range);
                document.execCommand('copy');
                selection.removeAllRanges();
                setCopied(true);
               setShowCopiedText(true); // Show the "Copied!" text

                setTimeout(() => {
                    setShowCopiedText(false);
                }, 2000);
            }
        }
        // if (answerTextElement) {
        //     const answerText = answerTextElement.textContent || ''; // Use an empty string as a fallback
        //     const textArea = document.createElement('textarea');
        //     textArea.value = answerText;
        //     document.body.appendChild(textArea);
        //     textArea.select();
        //     document.execCommand('copy');
        //     document.body.removeChild(textArea);
        //     setCopied(true);
        //     setShowCopiedText(true); // Show the "Copied!" text
        //     setTimeout(() => {
        //         setShowCopiedText(false); // Hide the "Copied!" text after 2 seconds
        //     }, 2000);
        // }
    };
    const handleDownload = (func: any) => {
        handleCitationDownload(func.slice(24, -2))
    }

    const handleFeedbackClick = async (open: boolean, id: number) => {

        try {
            const response = await getChatComments(id);
            setShowFeedbackModal(open)
            if (response.length > 0) {
                setComments(response);
            }
            else {
                setComments([]);
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    return (
        <Stack verticalAlign="space-between" >
            <Stack.Item className={clsx(
                " rounded",
                "mw-lg-900px"
            )} style={{ marginTop: '1rem', fontFamily: "Lexend Deca" }}>


                {answer?.references.length > 0 && (
                    <>
                        <div className={styles.quickSearchParent}>
                            <ManageSearchIcon className={styles.boltIcon} />
                            <div className={styles.header}>Sources</div>
                        </div>
                        <div className={styles.loadingSourcesParent}>
                            {titles?.map((title, index) =>
                                answer?.references[index]?.url.includes('https://') ? (
                                    <a //link
                                        href={answer.references[index].url}
                                        target="_blank"
                                        style={{
                                            backgroundColor: activeTheme === 'dark'
                                                ? (isHoveredS[index] ? '#292926' : 'transparent')
                                                : (isHoveredS[index] ? '#f3f2f1ff' : 'transparent'),

                                        }}
                                        key={index}
                                        className={`${styles.LoadedSources} ${styles.sourceStylesWeb}`}
                                        onMouseEnter={() => handleMouseEnter(index)}
                                        onMouseLeave={() => handleMouseLeave(index)}

                                    >
                                        <div style={{ marginBottom: title.length > 20 ? '0rem' : '1.1rem', }} className={styles.loadingSource}>
                                            {`${title.length > 38 ? title.substring(0, 38) + '...' : title}`}</div>
                                        <div
                                            className={`text-muted ${styles.source}`}


                                        >

                                            <div className="d-flex justify-content-between" >
                                                <div>
                                                    <img
                                                        src={favicon[index]}
                                                        alt={`Favicon ${index}`}
                                                        className={styles.image}
                                                    />
                                                    <span className={styles.siteName}>  {`${siteName[index].length > 11 ? siteName[index].substring(0, 11) + '...' : siteName[index]}`}</span>

                                                </div>
                                                <span className={`badge badge-pill badge-secondary ${styles.sizing}`}>{`${answer?.references[index]?.order}`}</span>


                                            </div>
                                        </div>
                                    </a>
                                ) : (
                                    <a //file

                                        onClick={() => handleCitationDownload(filepath[index])}
                                        style={{
                                            backgroundColor: activeTheme === 'dark'
                                                ? (isHoveredS[index] ? '#292926' : 'transparent')
                                                : (isHoveredS[index] ? '#f3f2f1ff' : 'transparent'),
                                        }}
                                        key={index}
                                        className={`${styles.LoadedSources} ${styles.sourceStylesFile}`}
                                        onMouseEnter={() => handleMouseEnter(index)}
                                        onMouseLeave={() => handleMouseLeave(index)}

                                    >
                                        <div style={{ marginBottom: title.length > 20 ? '0rem' : '1.1rem' }}>
                                            {`${title.length > 38 ? title.substring(0, 38) + '...' : title}`}</div>
                                        <div
                                            className={`text-muted ${styles.source}`}
                                        >

                                            <div className="d-flex justify-content-between" >
                                                <div>
                                                    <img
                                                        src={favicon[index]}
                                                        className={styles.image}
                                                        alt={`Favicon ${index}`}
                                                    />
                                                    <span style={{ marginTop: '0.2rem' }}>{`${siteName[index].length > 11 ? siteName[index].substring(0, 11) + '...' : siteName[index]}`}</span>
                                                </div>

                                                <span className={` ${styles.sizing}`}>


                                                    <span className="badge badge-pill badge-secondary" >{`${answer?.references[index]?.order}`}</span>

                                                </span>
                                            </div>

                                        </div>


                                    </a>
                                )
                            )}

                        </div>


                    </>
                )}
            </Stack.Item>

            <Stack.Item className={clsx(
                'text-dark ',
                `${'text-start'}  ${'mw-lg-700px'}`
            )}>
                <Stack.Item grow style={{ marginBottom: (parsedAnswer.followupQuestions.some(question => question.trim() == '') && last) ? "10rem" : '0' }}>
                    <div className="d-flex justify-content-between">
                        <div style={{ marginTop: answer.references.length > 0 ? '1.6rem' : '0rem', marginBottom: '-8px' }}
                            className="d-flex flex-row align-items-center">
                            <SortIcon className={styles.sort}></SortIcon>
                            <div className={styles.header} >Answer</div>
                        </div>
                        {/* copy content  */}
                        <div className="text-start">
                            <IconButton
                                style={{
                                    color: activeTheme === 'dark' ? (isHovered ? 'rgb(32, 128, 141)' : '#626261') : (isHovered ? 'rgb(32, 128, 141)' : 'rgb(19, 52, 59)'),
                                    transition: 'color 0.3s ease',
                                    visibility: showCopiedText ? 'hidden' : 'visible',

                                }}
                                className={`inline-block ${styles.copyButton}`}
                                title="Copy answer"
                                ariaLabel="Copy answer"
                                onClick={handleCopyAnswerText}
                                disabled={!answer.thoughts}
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                            >
                                <Clipboard size={20} weight="light" />
                            </IconButton>
                            {showCopiedText &&
                                <div className={styles.checkedText}>
                                    <CheckIcon className={styles.CheckIcon} />
                                    Copied!
                                </div>}
                        </div>
                    </div>
                    <div className={styles.answerTextSize} style={{
                        // whiteSpace: "pre-line",
                        fontSize: shareQueryParam !== "Allow-Embed-Only" ? "13px" : "13px",
                    }}
                        id='answer' ref={answerTextRef}>
                        {/* <div dangerouslySetInnerHTML={{ __html: parsedTextt }}></div> */}
                        <Markdown
                            className="AnswerChat"
                            // remarkPlugins={[remarkMath, remarkGfm]}
                            // rehypePlugins={[rehypeKatex, rehypeRaw]}
                            remarkPlugins={[remarkGfm]}
                            rehypePlugins={[rehypeRaw]}
                            // children={parsedText}
                            components={{
                                a(props) {

                                    const { children, ...rest } = props;
                                    if (props.node) {
                                        const childElement = props.node.children[0] as {
                                            type: string;
                                            tagName: string;
                                            properties: Record<string, any>;
                                            children: any[]; // Update this with the actual structure of your children
                                            position: Record<string, any>;
                                        };
                                        const childChildren = childElement.children;

                                        if (childChildren?.length > 0 && childChildren[0].type === 'text') {
                                            const value = childChildren[0].value;

                                            if (props.href) {

                                                return (
                                                    <a href={props.href} target="_blank"><sup>{value}</sup></a>
                                                )
                                            }

                                            else if (props.onClick) {

                                                return (
                                                    <a onClick={() => handleDownload(props.onClick)} target="_blank"><sup>{value}</sup></a>
                                                );
                                            }
                                        } else {
                                            return <a {...rest}>{children}</a>;
                                        }
                                    }
                                    else {
                                        <a {...rest} >{children}</a>
                                    }
                                },
                                code(props) {
                                    const { children, className, node, ...rest } = props;
                                    const match = /language-(\w+)/.exec(className || "");
                                    return match ? (
                                        <SyntaxHighlighter
                                            // {...rest}
                                            PreTag="div"
                                            children={String(children).replace(/\n$/, "")}
                                            language={match[1]}
                                            style={oneLight}
                                        />
                                    ) : (
                                        <code {...rest} className={className}>
                                            {children}
                                        </code>
                                    );
                                }, ul(props) {
                                    const { children, node, ...rest } = props;
                                    if (node) {
                                        const id = node.properties?.id;
                                        if (id === 'mathEquation') {
                                            return <BlockMath>{children}</BlockMath>;
                                        }
                                    }
                                    return <ul {...rest}>{children}</ul>;
                                },
                            }}
                        >
                            {parsedTextt}
                        </Markdown>

                    </div>

                    {isSuperUser && ( // Conditionally render the IconButton for thought process if the user is a superuser
                        <IconButton
                            style={{
                                color: activeTheme === 'dark' ? (isHovered ? 'rgb(32, 128, 141)' : '#626261') : (isHovered ? 'rgb(32, 128, 141)' : 'rgb(19, 52, 59)'),
                                transition: 'color 0.3s ease', marginLeft: "-4px", padding: "0.4px"
                            }}
                            className={styles.thoughtButton}
                            title="Show thought process"
                            ariaLabel="Show thought process"
                            onClick={() => onThoughtProcessClicked()}
                            disabled={!answer.thoughts}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                        ><Lightbulb size={18} weight="light" /></IconButton>
                    )}
                    {isSuperUser && ( // Conditionally render the IconButton for supporting content if the user is a superuser
                        <IconButton
                            style={{
                                color: activeTheme === 'dark' ? (isHoveredSC ? 'rgb(32, 128, 141)' : '#626261') : (isHoveredSC ? 'rgb(32, 128, 141)' : 'rgb(19, 52, 59)'),
                                transition: 'color 0.3s ease', marginLeft: "-4px",
                            }}
                            className={styles.thoughtButton}

                            title="Show supporting content"
                            ariaLabel="Show supporting content"
                            onClick={() => onSupportingContentClicked()}
                            disabled={!answer.data_points.length}
                            onMouseEnter={() => setIsHoveredSC(true)}
                            onMouseLeave={() => setIsHoveredSC(false)}
                        ><Files size={18} weight="light" /></IconButton>
                    )}
                    {
                        showRegenerate && (
                            <IconButton
                                style={{
                                    color: activeTheme === 'dark' ? (isHoveredRG ? 'rgb(32, 128, 141)' : '#626261') : (isHoveredRG ? 'rgb(32, 128, 141)' : 'rgb(19, 52, 59)'),
                                    transition: 'color 0.3s ease', marginTop: '0.6rem', marginBottom: '1rem', marginLeft: "-4px"
                                }}
                                title='Regenerate response'
                                onClick={() => onRegenerateClicked()}
                                onMouseEnter={() => setIsHoveredRG(true)}
                                onMouseLeave={() => setIsHoveredRG(false)}
                            ><ArrowCounterClockwise size={18} weight="light" /></IconButton>
                        )
                    }
                    {
                        <IconButton
                            style={{
                                color: activeTheme === 'dark'
                                    ? (is_upvote ? '#4cd964' : (isHoveredTU ? 'rgb(32, 128, 141)' : '#626261'))
                                    : (is_upvote ? '#4cd964' : (isHoveredTU ? 'rgb(32, 128, 141)' : 'rgb(19, 52, 59)')),
                                transition: 'color 0.3s ease', marginTop: '0.6rem', marginBottom: '1rem', marginLeft: "-4px",
                                fontWeight: 'bold',
                            }}
                            title='Good response'
                            onClick={() => onUpvoteResponseClicked()}
                            onMouseEnter={() => setIsHoveredTU(true)}
                            onMouseLeave={() => setIsHoveredTU(false)}
                        ><ThumbsUp size={18} weight="light" /></IconButton>
                    }
                    {
                        <IconButton
                            style={{
                                color: activeTheme === 'dark'
                                    ? (is_downvote ? 'red' : (isHoveredTD ? 'rgb(32, 128, 141)' : '#626261'))
                                    : (is_downvote ? 'red' : (isHoveredTD ? 'rgb(32, 128, 141)' : 'rgb(19, 52, 59)')),
                                transition: 'color 0.3s ease', marginTop: '0.6rem', marginBottom: '1rem', marginLeft: "-4px"
                            }}
                            title='Bad response'
                            onClick={() => onDownvoteResponseClicked()}
                            onMouseEnter={() => setIsHoveredTD(true)}
                            onMouseLeave={() => setIsHoveredTD(false)}
                        ><ThumbsDown size={18} weight="light" /></IconButton>
                    }
                    {
                        <IconButton
                            style={{
                                color: activeTheme === 'dark' ? (isHoveredC ? 'rgb(32, 128, 141)' : '#626261') : (isHoveredC ? 'rgb(32, 128, 141)' : 'rgb(19, 52, 59)'),
                                transition: 'color 0.3s ease', marginTop: '0.6rem', marginBottom: '1rem', marginLeft: "-4px"
                            }}
                            title='Feedback'
                            onClick={() => handleFeedbackClick(true, chat_id)}
                            onMouseEnter={() => setIsHoveredC(true)}
                            onMouseLeave={() => setIsHoveredC(false)}
                        ><ChatsCircle size={18} weight="light" /></IconButton>
                    }
                    {
                        showFeedbackModal ? <Feedbacks setShowFeedbackModal={setShowFeedbackModal} showFeedbackModal={showFeedbackModal} feedbackComments={comments} chat_id={chat_id} user_email={userEmail}></Feedbacks> : <></>

                    }

                </Stack.Item>


                <div className={`mb-2 ${styles.horizontal}`}></div>

                {!!parsedAnswer.followupQuestions.some(question => question.trim() !== '') && showFollowupQuestions && agentType == "QnA" && onFollowupQuestionClicked && (
                    <Stack.Item className={styles.StackItem}>
                        <Stack.Item>
                            <div className={`d-flex align-items-center ${styles.queueDiv}`} >
                                <QueueIcon style={{ marginRight: '0.35rem' }} />
                                <span className={styles.header}>Follow Up Questions</span>
                            </div>
                        </Stack.Item>
                        <Stack className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                            <Stack.Item>
                                {parsedAnswer.followupQuestions.map((question, i) => {

                                    if (question.trim() !== '') {
                                        // Remove leading number and period
                                        const referenceWithoutNumber = removeLeadingNumber(question);
                                        return (
                                            <ul className={styles.follow} key={i}>
                                                <li
                                                    style={{
                                                        color:
                                                            activeTheme === 'dark'
                                                                ? hoverStates[i].isHovered
                                                                    ? '#59c0fa'
                                                                    : 'white'
                                                                : hoverStates[i].isHovered
                                                                    ? 'rgb(32, 127, 140)'
                                                                    : 'rgb(19, 52, 59)',
                                                        fontSize: shareQueryParam !== "Allow-Embed-Only" ? "14px" : "13px",
                                                    }}
                                                    className={styles.followupQuestion}
                                                    onMouseEnter={() =>
                                                        setHoverStates((prevStates) =>
                                                            prevStates.map((state, index) =>
                                                                index === i ? { ...state, isHovered: true } : state
                                                            )
                                                        )
                                                    }
                                                    onMouseLeave={() =>
                                                        setHoverStates((prevStates) =>
                                                            prevStates.map((state, index) =>
                                                                index === i ? { ...state, isHovered: false } : state
                                                            )
                                                        )
                                                    }
                                                    title={question}
                                                    onClick={() => onFollowupQuestionClicked(referenceWithoutNumber)}
                                                >
                                                    <div className="d-flex justify-content-between">
                                                        <span> {referenceWithoutNumber}</span>
                                                        <AddIcon />
                                                    </div>
                                                    <div className={`mt-2 ${styles.horizontal}`}></div>
                                                </li>
                                            </ul>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                )}


            </Stack.Item>
        </Stack >
    );
};
