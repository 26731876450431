//user_id in request. user in response
//agent_id in request agent in response
//project_id in request project in response
//team_id in request team in response
//org_id in request org in response

//New Agent creation interfaces
export type NewChatTurn = {
    role: string;
    content: string;
};

export type NewChatRequest = {
    project_id: number;
    history: NewChatTurn[];
    category: number | null;
    user_id: number;
    created_by: string;
    overrides?: Record<string, any>;
};
//response
export type NewChatResponse = {
    output: string;
    history: NewChatTurn[];
    agent_image: string;
    prefix_prompt: string;
    agent_name: string;
    new_image: boolean;
    category: number;
    description: string;
};

// Chat interfaces
export const enum Approaches {
    RetrieveThenRead = "rtr",
    ReadRetrieveRead = "rrr",
    ReadDecomposeAsk = "rda"
}

export type AskRequestOverrides = {
    semanticRanker?: boolean;
    semanticCaptions?: boolean;
    excludeCategory?: string;
    top?: number;
    temperature?: number;
    promptTemplate?: string;
    promptTemplatePrefix?: string;
    promptTemplateSuffix?: string;
    suggestFollowupQuestions?: boolean;
    sources?: boolean;
    cache_skip?: boolean;
    mode_override?:string;
};

export type ChatTurn = {
    user?: string;
    bot?: string;
};

export type ChatRequest = {
    history: ChatTurn[];
    approach: Approaches;
    category: string;
    chat_thread_id: number | null;
    user_id: number;
    created_by: string;
    overrides?: AskRequestOverrides;
    model?: string;
};

export type AskResponse = {
    data_points: string[];
    answer: string;
    sources: string;
    followup_questions: string[];
    thoughts: string | null;
    indexes: string[];
    references: References[];
    error?: string;
    chatlog_id: number;

};

export type ChatLogResponse = {
    id: number;
    user: number;
    agent: number;
    question: string;
    response: AskResponse;
    created_by: string;
    created_on: string;
    is_deleted: boolean;
    feedback: ChatFeedbackResponse;
    comments: ChatCommentResponse[];
}
export type ChatLogWithAgentResponse = {
    id: number;
    user: number;
    agent: AgentCardResponse;
    question: string;
    response: AskResponse;
    created_by: string;
    created_on: string;
    is_deleted: boolean;
    feedback: ChatFeedbackResponse;
    comments: ChatCommentResponse[];
}

export type ChatLogByPageResponse = {
    count: number;
    num_pages: number;
    current_page: number;
    chatlogs: ChatLogWithAgentResponse[];
}

export type ExportChatLogResponses = ChatLogWithAgentResponse[];

export type References = {
    order: number;
    url: string;
    number: number;
}

export type ChatUpvoteRequest = {
    chat_id: number;
    is_upvote: boolean;
    created_by: string;
}

export type ChatDownvoteRequest = {
    chat_id: number;
    is_downvote: boolean;
    created_by: string;
}
export type ChatFeedbackResponse = {
    id: number;
    is_upvote: boolean;
    is_downvote: boolean;
    created_on: string;
    created_by: string;
    modified_on: string;
}

export type ChatCommentRequest = {
    chat_id: number;
    comment: string;
    created_by: string;
}
export type ChatCommentResponse = {
    id: number;
    comment: string;
    created_on: string;
    created_by: string;
}

// Pdf Index and Data interfaces



export type FileIndexRequest = {
    agent_id: number;
    user_id: number;
    file_name: string;
    created_by: string;
    file: File;
}

export type FileIndexResponse = {
    index_name: string;
    error?: string;
    status: string;
    pdfdata: FileTableResponse[];
}

export type FileTableResponse = {
    id: number;
    agent: number;
    user: User;
    file_name: string;
    blob_url: string;
    created_by: string;
    created_on: string;
    modified_by: string;
    modified_on: string;
    status: string;

}

export type ModifiedFileTableResponse = FileTableResponse & {
    modified_filename: string;
};

export type FileByAgentResponse = {
    count: number;
    num_pages: number;
    current_page: number;
    files: FileTableResponse[];
}
// Weblinks Index and Data interfaces



export type WeblinkIndexRequest = {
    agent_id: number;
    user_id: number;
    web_url: string[];
    created_by: string;
}
export type WeblinkIndexResponse = {
    urls_extracted: string[];
    urls_not_extracted: string[];
    weblinkdata: WeblinkTableResponse[];
}
export type WeblinkTableResponse = {
    id: number;
    agent: number;
    user: User;
    web_url: string;
    created_by: string;
    created_on: string;
    modified_by: string;
    modified_on: string;
    is_deleted: boolean;
    status: string;

}
export type LinksResponse = {
    id: number;
    agent: number;
    user: User;
    url: string;
    created_by: string;
    created_on: string;
    modified_by: string;
    modified_on: string;
    is_deleted: boolean;
    type: "youtube" | "weblink";
    status: string;

}

export type WeblinksByAgentResponse = {
    count: number;
    num_pages: number;
    current_page: number;
    weblinks: WeblinkTableResponse[];
}

//all indexed data

export type AllDataResponse = {
    count: number;
    num_pages: number;
    current_page: number;
    data: any[];
}

// Youtube Index and Data interfaces

export type YoutubeIndexRequest = {
    agent_id: number;
    user_id: number;
    yt_url: string[];
    created_by: string;
}
export type YoutubeIndexResponse = {
    urls_extracted: string[];
    urls_not_extracted: string[];
    youtubedata: YoutubeTableResponse[];
}
export type YoutubeTableResponse = {
    id: number;
    agent: number;
    user: User;
    yt_url: string;
    created_by: string;
    created_on: string;
    modified_by: string;
    modified_on: string;
    is_deleted: boolean;
    status: string;

}

export type YTLinksByAgentResponse = {
    count: number;
    num_pages: number;
    current_page: number;
    youtube_links: YoutubeTableResponse[];
}

// Text Index and Data interfaces

export type TextIndexResponse = {
    message: string;
    error?: string;
    textdata: TextTableResponse[];
};

export type TextTableResponse = {
    id: number;
    agent: number;
    user: User;
    text: string;
    file_name: string;
    created_by: string;
    created_on: string;
    modified_by: string;
    modified_on: string;
    is_deleted: boolean;
    status: string;

}

export type TextIndexRequest = {
    agent_id: number;
    user_id: number;
    text: string;
    created_by: string;
    upload_source: string;
}

export type TextblobsByAgentResponse = {
    count: number;
    num_pages: number;
    current_page: number;
    texts: TextTableResponse[];
}

// User related interfaces

export type User = {
    id: number;
    email: string;
    date_joined?: string;
    first_name?: string;
    last_name?: string;
    is_staff?: boolean;
    is_superuser?: boolean;
    organization: UserOrganization;
}

export type User1 = {
    id: number;
    email: string;
    first_name?: string;
    last_name?: string;
    organization: UserOrganization;
}

export type ProjectInfo = {
    id: number;
    project_name: string;
    team: Team;
}

export type Team ={
    id: number;
    team_name: string;
}

export type LoginRequest = {
    email: string;
    password: string;
    deviceId: boolean;
};

export type LoginResponse = {
    user?: string;
    id?: string
    is_superuser?: string;
    is_staff?: string;
    org_id?: string;
    error?: string;
    message?: string;
    data?: LoginResponse[];
};
export type getTokenRequest = {
    email: string;
};
export type getTokenResponse = {
    message?: string;
};

export type VerifyTokenRequest = {
    email: string;
    password: string;
    token: string;
}
export type VerifyTokenResponse = {
    email: string;
    token: string;
    error?: string;
    message?: string;
    data?: LoginResponse[];
}



// Look Feel Config interfaces



export type LookFeelCardRequest = {
    config_name: string;
    header_logo: File | string;
    square_logo: File | string;
    sample_q1: string;
    sample_q2: string;
    sample_q3: string;
    tagline: string;
    chat_placeholder: string;
    navbar_bg_color: string;
    navbar_text_color: string;
    layout_bg_color: string;
    created_by: string;
};

export type LookFeelCardResponse = {
    id: number;
    config_name: string;
    header_logo: string;
    square_logo: string;
    sample_q1: string;
    sample_q2: string;
    sample_q3: string;
    tagline: string;
    chat_placeholder: string;
    created_on: string;
    created_by: string;
    navbar_bg_color: string;
    navbar_text_color: string;
    layout_bg_color: string;
    is_active: boolean;
    is_deleted: boolean;
    error?: string;
};

export type EditLookFeelCardRequest = {
    id: number;
    config_name: string;
    header_logo: File | string;
    square_logo: File | string;
    sample_q1: string;
    sample_q2: string;
    sample_q3: string;
    tagline: string;
    chat_placeholder: string;
    navbar_bg_color: string;
    navbar_text_color: string;
    layout_bg_color: string;
};

// recent teams interfaces

export type RecentTeamResponse = {
    id: number;
    team: TeamResponse;
}

//recent project interfaces
export type RecentProjectResponse = {
    id: number;
    project: ProjectResponse;
}

// Agent interfaces



export type AgentCardRequest = {
    agent_name: string;
    user_id: number;
    project_id: number;
    prefix_prompt: string;
    domain: string;
    sample_q1: string;
    sample_q2: string;
    sample_q3: string;
    cloned_from: number | null;
    created_by: string;
    need_alias: boolean;
    agent_image: string | File;
    access: string;
    status: string;
    custom_instructions: string[];
    agent_type: number;
    llm_config: number;
};


export type AgentCardResponse = {
    id: number;
    user: number;
    project: number;
    agent_name: string;
    industry_area: string;
    domain: string;
    sample_q1: string;
    sample_q2: string;
    sample_q3: string;
    instructions: InstructionResponse[];
    cloned_from: number | null;
    created_on: string;
    created_by: string;
    is_active: boolean;
    is_deleted: boolean;
    error?: string;
    alias: string;
    agent_image: string;
    prefix_prompt: string;
    access: string;
    status: string;
    agent_type: AgentType;
    llm_config:number | null;
};

export type AgentCardResponse2 = {
    id: number;
    // user: number;
    project: number;
    agent_name: string;
    industry_area: string;
    domain: string;
    sample_q1: string;
    sample_q2: string;
    sample_q3: string;
    instructions: InstructionResponse[];
    cloned_from: number | null;
    created_on: string;
    created_by: string;
    is_active: boolean;
    is_deleted: boolean;
    error?: string;
    alias: string;
    agent_image: string;
    prefix_prompt: string;
    access: string;
    status: string;
    agent_type: AgentType;
    llm_config:number | null;
    user: User1;
};

export type CreateCustomInstructionRequest = {
    agent:number;
    created_by: string;
    custom_instructions: string[];
};

export type CreateCustomInstructionResponse = {
    id: number;
    custom_instructions: string;
    is_deleted: string;
    created_by: string;
    created_on: string;
    agent: number;
};

//for global searchbar result
export type AgentCardResponseWithTeam = {
    id: number;
    project: ProjectInfo;
    agent_name: string;
    industry_area: string;
    domain: string;
    sample_q1: string;
    sample_q2: string;
    sample_q3: string;
    instructions: InstructionResponse[];
    cloned_from: number | null;
    created_on: string;
    created_by: string;
    is_active: boolean;
    is_deleted: boolean;
    error?: string;
    alias: string;
    agent_image: string;
    prefix_prompt: string;
    access: string;
    status: string;
    agent_type: AgentType;
    llm_config:number | null;
    user: User1;
};

export type AgentsByPageResponse = {
    count: number;
    num_pages: number;
    current_page: number;
    agents: AgentCardResponse2[];
}

export type InstructionResponse = {
    id: number;
    agent: number;
    created_on: string;
    instruction: string;
}


export type EditAgentCardRequest = {
    id: number;
    agent_name: string;
    domain: string;
    sample_q1: string;
    sample_q2: string;
    sample_q3: string;
    prefix_prompt: string;
    agent_image: string | File;
    cloned_from: number | null;
    need_alias: boolean;
    access: string;
    status: string;
    custom_instructions: string[];
    created_by: string;
    agent_type: number;
    llm_config: number;
};

export type PastPromptResponse = {
    id: number;
    prefix_prompt: string;
    history_date: string;
}
export type UserAgentResponseByAgent = {
    id: number;
    user: User;

}

export type UserAgentRequest = {
    agent_id: number;
    users: number[];
    created_by: string;
}
export type EditUserAgentRequest = {
    agent_id: number;
    user_id: number;
}

export type AgentAliasResponse = {
    id: number;
    project: ProjectResponse;
    agent_name: string;
    alias: string;
    error?: string;
};


// Job Function interfaces



export type JobFunction = {
    job_function: string;
    instructions: string[];
};

export type JobFunctionsResponse = {
    allJobFunctions: JobFunction[];
    error?: string;
};

// ActiveAgent interfaces



export type ActiveAgentRequest = {
    agent_id: number;
    user_id: number;
    created_by: string;
}

export type ActiveAgentResponse = {
    id: number;
    agent: number;
    user: number;
    created_on: string;
    created_by: string;
    modified_by: string;
    modified_on: string;
}




// Rating interfaces



export type RatingsRequest = {
    agent_id: number;
    user_id: number;
    is_upvote: boolean;
    created_by: string;
}

export type RatingsResponse = {
    id: number;
    agent: number;
    user: number;
    is_upvote: boolean;
    created_on: string;
    created_by: string;
    modified_by: string;
    modified_on: string;
}



// Review interfaces



export type ReviewRequest = {
    agent_id: number;
    user_id: number;
    user_review: string;
    created_by: string;
}

export type ReviewResponse = {
    id: number;
    agent: number;
    user: number;
    user_review: string;
    created_by: string;
    created_on: string;
    modified_by: string;
    modified_on: string;
}



// Team interfaces



export type TeamRequest = {
    org_id: number;
    user_id: number;
    team_name: string;
    created_by: string;
}

export type TeamResponse = {
    id: number;
    org: number;
    team_name: string;
    is_deleted: boolean;
    created_by: string;
    created_on: string;
    modified_by: string;
    modified_on: string;
}

export type EditTeamRequest = {
    team_id: number;
    team_name: string;
    modified_by: string;
}



// UserTeam interfaces



export type UserTeamRequest = {
    team_id: number;
    users: number[];
    created_by: string;
}

export type UserTeamResponseByTeam = {
    id: number;
    user: User;
    role: string;
}

export type UserTeamResponseByUser = {
    id: number;
    team: TeamResponse;
}

export type EditUserTeamRequest = {
    team_id: number;
    user_id: number;
}
export type EditUserRoleRequest = {
    team_id: number;
    user_id: number;
    role: string;
    modified_by: string;
}

export type EditUserRoleResponse = {
    team_id: number;
    user_id: number;
    role: string;
    modified_by: string;
    modified_on: string;
}

// Project interfaces



export type ProjectRequest = {
    team_id: number;
    user_id: number;
    project_name: string;
    created_by: string;
}

export type ProjectResponse = {
    id: number;
    team: number;
    project_name: string;
    is_deleted: boolean;
    created_by: string;
    created_on: string;
    modified_by: string;
    modified_on: string;
}

export type EditProjectRequest = {
    project_id: number;
    project_name: string;
    modified_by: string;
}

export type ProjectResponseWithTeamObj = {
    id: number;
    team: TeamResponse;
    project_name: string;
    is_deleted: boolean;
    created_by: string;
    created_on: string;
    modified_by: string;
    modified_on: string;
}


// UserProject interfaces



export type UserProjectRequest = {
    project_id: number;
    users: number[];
    created_by: string;
}

export type UserProjectResponseByProject = {
    id: number;
    user: User;
}

export type UserProjectResponseByUser = {
    id: number;
    project: ProjectResponseWithTeamObj;
}

export type EditUserProjectRequest = {
    project_id: number;
    user_id: number;
}


// Notification interfaces



export type NotificationResponse = {
    id: number;
    recipient: number;
    agent: number | null;
    content: string;
    is_read: boolean,
    created_on: string;
    is_deleted: boolean;
}


//Category interfaces


export type AgentCategoryResponse = {
    id: number;
    agent: AgentCardResponse2;
    category: CategoryResponse;
    created_on: string;
    created_by: string;
}

export type CategoryResponse = {
    id: number;
    name: string;
    description: string;
    created_on: string;
    created_by: string;
}

// Starred Agents

export type StarredAgentResponse = {
    id: number;
    agent: AgentCardResponse2;
}

export type RecentAgentResponse = {
    id: number;
    agent: AgentCardResponse2;
}


// Hubspot Support Form interfaces

export type SupportRequest = {
    agent_id: number | null;
    description_ejento: string;
}

// Total counts of agents,teams, users and projects

export type TotalCounts = {
    count_data: {
        userCount: number;
        userPercentage: number;
        agentCount: number;
        agentPercentage: number;
        teamCount: number;
        teamPercentage: number;
        projectCount: number;
        projectPercentage: number;
    }
}

//Most Active Users 

export type MostActiveUsers = {
    user_engagement: Array<{
        user_fname: string | null;
        user_lname: string | null;
        user_email: string;
        interaction_count: number;
    }>;
};

// Most Active Agents
export interface MostActiveAgents {
    id: number;
    agent_name: string;
    industry_area: string;
    domain: string;
    sample_q1: string;
    sample_q2: string;
    sample_q3: string;
    prefix_prompt: string;
    is_active: boolean;
    is_deleted: boolean;
    created_by: string;
    created_on: string;
    user: number;
    project: number;
    cloned_from: number | null;
}

// Recenlty Added Users

export type RecentlyAddedusers = {
    id: number;
    email: string;
    date_joined?: string;
    first_name?: string;
    last_name?: string;
    is_staff?: boolean;
    is_superuser?: boolean;
    is_active?: boolean;

}
// User token
export interface UserToken {
    user_fname: string | null;
    user_lname: string | null;
    user_email: string;
    total_tokens: number;
}
export type userTokenData = {
    user_token: UserToken[];
}
// Agent token

export type AgentToken = {
    agent_name: string;
    total_tokens: number;
};

export type AgentTokenData = {
    agent_token_data: AgentToken[];
};

// Team token
export type TeamTokenData = {
    team_name: string;
    agents: Agent[];
    total_tokens_count: number;
};

export type Agent = {
    id: number;
    agent_name: string;
};

export type TeamTokenResponse = {
    team_token_data: TeamTokenData[];
};

// Daily tokens
export type DailyToken = {
    date: string;
    totalTokens: number;
};

export type DailyTokensData = {
    daily_tokens: DailyToken[];
};

// Weekly tokens
export type WeeklyToken = {
    weekStartDate: string;
    totalTokens: number;
};

export type WeeklyTokensData = {
    weekly_tokens: WeeklyToken[];
};

// Monthly tokens
export type MonthlyToken = {
    monthStartDate: string;
    totalTokens: number;
};

export type MonthlyTokensData = {
    monthly_tokens: MonthlyToken[];
};

// User Engagement
export interface UserEngagement {
    user_fname: string | null;
    user_lname: string | null;
    user_email: string;
    interaction_count: number;
}
export type userEngagementData = {
    user_engagement: UserEngagement[];
}

// Agent Engagement

export type AgentEngagement = {
    agent_name: string;
    interaction_count: number;
}
export type AgentEngagementData = {
    agent_engagement: AgentEngagement[];
}

//Team Engagement
export type TeamEngagement = {
    team_name: string;
    agents: Agent[];
    total_interaction_count: number;
}

export type TeamEngagementResponse = {
    team_engagement: TeamEngagement[];
}

// Daily Engagement
export type DailyEngagement = {
    date: string;
    interaction_count: number;
}

export type DailyEngagementResponse = {
    daily_engagement: DailyEngagement[];
}

// Weekly Engagement
export type WeeklyEngagement = {
    week_start_date: string;
    interaction_count: number;
}

export type WeeklyEngagementResponse = {
    weekly_engagement: WeeklyEngagement[];
}
// Monthly Engagement

export type MonthlyEngagement = {
    month_start_date: string;
    interaction_count: number;
}

export type MonthlyEngagementResponse = {
    monthly_engagement: MonthlyEngagement[];
}

//Organization interfaces
export type OrganizationResponse = {
    org_id: number | null;
    org_name: string;
}
//Configuration interfaces
export type ConfigurationResponse = {
    org_id: number;
    model_deployment: string[];
    modified_by: string;
    modified_on: string;
}

export type EditModelConfigurationRequest = {
    org_id: number;
    model_deployment: string[];
    modified_by: string;

}

// FAQ Categories

export type FAQCategories = {
    id: number;
    faq_category_name: string;
}

// Category wise questions

export type Questions = {
    id: number;
    question: string;
    answer: string;
    faq_category_id: number;
}

export type Tutorials = {
    id: number;
    title: string;
    thumbnail_url: string;
}

export type OneTutorial = {
    id: number;
    url: string;
    createdBy: string;
    createdOn: string;
    modifiedBy: string;
    title: string;
    description: string
}; export type ChatThreads = {
    agent: number;
    created_by: string;
    created_on: string;
    id: number;
    is_deleted: boolean;
    modified_by: string;
    modified_on: string;
    title: string;
    user: number;

}

export type ChatByThread = {
    thread_id: number;
    chatlog: ChatLogResponse[]
}

//evaluation testing models

//all evaluations

export type AllEvaluationTests = {
    id: number;
    agent: number;
    answer_similarity: number | null;
    answer_relevancy: number | null;
    faithfulness: number | null;
    context_recall: number | null;
    status: string;
    created_by: string;
    created_on: string;
    initiated_on: string;
}

export type CreateEvaluationRequest = {
    agent_id: number;
    dataset: number;
}

export type EvaluationDataset = {
    id: number;
    created_by: string;
    created_on: string;
    modified_by: string;
    modified_on: string;
    dataset: number[];
    agent: number;
}

export type CreateDatasetRequest = {
    agent_id: number;
    dataset: number[];
}

export type EvaluationTest = {
    id: number;
    created_by: string;
    created_on: string;
    modified_by: string;
    modified_on: string;
    agent: number;
    answer_relevancy: number;
    answer_similarity: number;
    context_recall: number;
    faithfulness: number;
    initiated_on: string;
    query_result: QueryResult[]; // You might want to define a proper type for query_result
    status: string;
}
export type QueryResult = {
    question: string;
    contexts: string[];
    answer: string;
    ground_truths: string[];
    answer_similarity: number;
    faithfulness: number;
    answer_relevancy: number;
    context_recall: number;
    prompt_prefix: string;
    optimized_prompt: string;
    chat_history: ChatHistory[];
}
export type ChatHistory = {
    bot: string;
    user: string;
}

export type LLMConfigResponse = {
    id: number;
    model_deployment: string;
    model_name: string;
    is_enabled: boolean;
    is_default: boolean;
}

export type ActiveConfigResponse = {
    llm_config_id: number;
    model_name: string;
    model_deployment: string;
}

export type ActiveConfigRequest = {
    agent_id: number;
    llm_config_id: number;
}

//organizations
interface Organization {
    id: number;
    org_name: string;
    domain: string;
}

export type AllOrgs = {
    id: number;
    organization: Organization;
    permission: string | null;
    created_by: string;
    created_on: string;
    user: number;
}

interface UserOrganization {
    id: number;
    org_name: string;
    domain: string;
    is_deleted: boolean;
    created_by: string;
    created_on: string;
    modified_by: string;
    modified_on: string;
}


export type ActiveAgentAccessResponse = {
    id: number;
    agent_name: string;
    access: string;
}
export type AgentType = {
    id: number;
    name: string;
    description: string;
    created_by: string;
    created_on: string;
    is_deleted: boolean;
    modified_by: string;
    modified_on: string;
}
export type FeatureFlag = {
    id: number,
    feature: string,
    flag: boolean
}

// sharepoint indexing

export type sharePointLink = {
    id: number;
    user: {
        id: number;
        email: string;
        first_name: string;
        last_name: string;
    };
    created_by: string;
    created_on: string;
    modified_by: string | null;
    modified_on: string | null;
    url: string;
    is_deleted: boolean;
    agent: number;
}

export type EditAgentAccessRequest = {
    id: number
    access: string
  }
  
  export type EditAgentAccessResponse = {
      id: number;
      access: string;
      agent_name: string;
    }

export type UserRole = {
    id: number;
    user: number;
    role: string;
}

export type userRoleInTeam = {
    id: number;
    user: number;
    role: string;
}
