/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx';
import { KTSVG, toAbsoluteUrl } from '../../../helpers';
import { useLayout } from '../../core';
import { Header } from './Header';
import { MetronicNavbar } from './Navbar';
import { ChatThreads, createStarredAgent, createThread, decryptData, deleteStarredAgent, deleteThread, editThread, encryptData, fetchLoginCookie, getAChatThread, getAllChatThreads, getOneAgent, getOneAgentForMagicLink, getOneStarredAgent, getUserAgentPermission, getUserRoleByAgentId } from '../../../../api';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import styles from './Header.module.scss';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import React from 'react';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import { Divider, IconButton, MenuItem, Popover, TextField, Tooltip } from '@mui/material';
import ModeEditSharpIcon from '@mui/icons-material/ModeEditSharp';
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import MoreHorizSharpIcon from '@mui/icons-material/MoreHorizSharp';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Gauge, ChatCircleText, GearSix, FolderNotch, Star, EnvelopeSimple } from "@phosphor-icons/react";
import defaultBotImg from '../../../../media_dsd/defaultBotImg.png'
import { toast } from 'react-toastify';
import CloseSharp from '@mui/icons-material/CloseSharp';

interface HeaderWrapperProps {
  isEmbedding: boolean;
  agentUpdate: boolean;
  setAgentUpdate: Dispatch<SetStateAction<boolean>>;
  setUpdateChatlog: Dispatch<SetStateAction<boolean>>;
  updateThreadList: boolean;
  updatechatlog: boolean;
  enableEdit: boolean;
  agentUpdateAfterDelete: boolean
  agentUpdateAfterEdit: boolean
  setUpdateThreadList: Dispatch<SetStateAction<boolean>>;
  setIsLoadingChat: Dispatch<SetStateAction<boolean>>;
  isLoadingChat: boolean
  setCurrentTab: Dispatch<SetStateAction<string>>;
  setUpdateThreadName: Dispatch<SetStateAction<boolean>>;
  updateThreadName: boolean;
  setRefreshChatPage: Dispatch<SetStateAction<boolean>>;
  refreshChatPageOnAgentCreation:boolean;
}

export function HeaderWrapper({refreshChatPageOnAgentCreation, setRefreshChatPage, updateThreadName, setUpdateThreadName, setIsLoadingChat, isLoadingChat, setUpdateThreadList, setCurrentTab, agentUpdateAfterEdit, isEmbedding, agentUpdate, setAgentUpdate, setUpdateChatlog, updateThreadList, updatechatlog, enableEdit, agentUpdateAfterDelete }: HeaderWrapperProps) {
  const { agentId } = useParams();
  const { config, classes } = useLayout();
  const [agentConfigName, setAgentConfigName] = useState('');
  const [activeAgentName, setActiveAgentName] = useState('');
  const [threads, setThreads] = useState<ChatThreads[]>([]);
  const [activeThread, setActiveThread] = useState<ChatThreads>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const shareQueryParam = decodeURIComponent(decryptData(queryParams.get('share')));
  const [loggedInUser, setLoggedInUser] = useState('');
  const [show, setShow] = useState(false);
  const [value, setValue] = useState('');
  const [isEditing, setIsEditing] = useState(false)
  const [threadToBeEdited, setThreadToBeEdited] = useState<number>()
  const [userEmail, setUserEmail] = useState('');
  const [threadName, setThreadName] = useState('');
  const activeTheme = localStorage.getItem("kt_theme_mode_value");
  const [warning, setWarning] = useState(false)
  const [isdelete, setIsDelete] = useState(false)
  const [threadToBeDeleted, setThreadToBeDeleted] = useState<number>()
  const [threadToShowActionsFor, setThreadToShowActionsFor] = useState<number>()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [threadsOfToday, setThreadsOfToday] = useState<ChatThreads[]>([]);
  const [threadsOfYesterday, setThreadsOfYesterday] = useState<ChatThreads[]>([]);
  const [threadsOfPrev7Days, setThreadsOfPrev7Days] = useState<ChatThreads[]>([]);
  const [threadsOlder, setThreadsOlder] = useState<ChatThreads[]>([]);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [loggedInUserEmail, setLoggedInUserEmail] = useState<string>("");
  const [userEditPermission, setUserEditPermission] = useState<boolean>(false)
  const [isSuper, setIsSuper] = useState<boolean>(false);
  const [createdBy, setCreatedBy] = useState<string | null>("");
  const [agentImg, setAgentImg] = useState<string>("");
  const [agentConfigImg, setAgentConfigImg] = useState('');
  const [isStarred, setIsStarred] = useState<boolean>(false);
  const [loggedInUserId, setLoggedInUserId] = useState<number>();
  const [agentAlias, setAgentAlias] = useState(""); const [access, setAccess] = useState<string>('');
  const [isStaff, setIsStaff] = useState(false);
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search);
  const tabParam = searchParams.get('tab');
  const [activeAgentId, setActiveAgentId] = useState(0)
  const [tab, setTab] = useState('');
  const [userRole, setUserRole] = useState<string>('')


  useEffect(() => {
    handlePermission()
  }, [createdBy, userEditPermission, location.search])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabParam = searchParams.get('tab');
    setTab(tabParam || '');
    const agentID = decodeURIComponent(decryptData(agentId));
    const parsedAgentID = parseInt(agentID, 10);
    if (parsedAgentID) {
      setActiveAgentId(parsedAgentID)
    }

  }, [location.search]);
  const userInfo = fetchLoginCookie();
  const decryptedRes = decryptData(userInfo);
  const userId = parseInt(decryptedRes?.id);
  const activeThreadID = localStorage.getItem('activeThread')

  useEffect(() => {
    const agentID = decodeURIComponent(decryptData(agentId));
    const activeAgentID = parseInt(agentID, 10);
    if (updateThreadName) {
      getThreads(activeAgentID, userId)
      if (activeThreadID) {
        getOneThread(parseInt(activeThreadID))
      }
      setUpdateThreadName(false)
    }
  }, [updateThreadName])
  useEffect(() => {
    const userEmail = decryptedRes?.user;
    const isSuperUser = decryptedRes?.is_superuser?.toLowerCase() === "true";
    const isStaffUser = decryptedRes?.is_staff?.toLowerCase() === "true";
    if (isStaffUser) {
      setIsStaff(true);
    } if (userId) {
      setLoggedInUserId(userId)
    }
    if (isSuperUser) {
      setIsSuper(true);
    }
    if (userEmail) {
      setLoggedInUserEmail(userEmail);
    }


  }, []);

  useEffect(() => {
    const userInfo = fetchLoginCookie();
    const decryptedRes = decryptData(userInfo);
    const userId = parseInt(decryptedRes?.id);
    const agentID = decodeURIComponent(decryptData(agentId));
    const activeAgentID = parseInt(agentID, 10);
    setActiveThread(undefined)
    setThreads([])
    setThreadsOfToday([])
    setThreadsOfYesterday([])
    setThreadsOfPrev7Days([])
    setThreadsOlder([])
    getStarredAgent(userId)
    getUserRoleByAgent(activeAgentID)
    if (activeAgentID) {
      getUserEditPermission(activeAgentID)
    }
  }, [agentId])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShow(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const userInfo = fetchLoginCookie();
    const decryptedRes = decryptData(userInfo);
    const userId = parseInt(decryptedRes?.id);
    const user_email = decryptedRes?.user;
    const agentID = decodeURIComponent(decryptData(agentId));
    const activeAgentID = parseInt(agentID, 10);
    const searchParams = new URLSearchParams(location.search);
    if (userId && !isNaN(activeAgentID) && !isNaN(userId) && location.pathname.startsWith('/agent/')) {
      activateFirstThread(activeAgentID, userId, true);
      setLoggedInUser(userId.toString());
    }
    if (user_email) {
      setUserEmail(user_email)
    }
  }, [agentId]);

  useEffect(() => {
    const userInfo = fetchLoginCookie();
    const decryptedRes = decryptData(userInfo);
    const userId = parseInt(decryptedRes?.id);
    const agentID = decodeURIComponent(decryptData(agentId));
    const activeAgentID = parseInt(agentID, 10);
    if (userId && !isNaN(activeAgentID) && !isNaN(userId) && location.pathname.startsWith('/agent/') && tab === 'chat' && !refreshChatPageOnAgentCreation) {
      persistThread()
    }

  }, [tab]);

  const persistThread = async () => {
    const thread = localStorage.getItem('activeThread')
    try {
      if (thread) {
        const response = await getAChatThread(parseInt(thread))
        if (response) {
          setActiveThread(response)
          setUpdateChatlog(true);
          setIsLoadingChat(true)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }
  useEffect(() => {
    const thread = localStorage.getItem('activeThread')
    const agentID = decodeURIComponent(decryptData(agentId));
    const activeAgentID = parseInt(agentID, 10);

    if (loggedInUser && !isNaN(activeAgentID) && !isNaN(parseInt(loggedInUser)) && location.pathname.startsWith('/agent/') && updateThreadList) {
      getThreads(activeAgentID, parseInt(loggedInUser))
      if (thread) {
        getOneThread(parseInt(thread))
      }
      setUpdateThreadList(false)
    }
  }, [updateThreadList])

  useEffect(() => {
    const thread = localStorage.getItem('activeThread')
    const agentID = decodeURIComponent(decryptData(agentId));
    const activeAgentID = parseInt(agentID, 10);
    if (loggedInUser && !isNaN(activeAgentID) && !isNaN(parseInt(loggedInUser)) && location.pathname.startsWith('/agent/')) {
      getThreads(activeAgentID, parseInt(loggedInUser))
      if (thread) {
        getOneThread(parseInt(thread))

      }
    }
  }, [enableEdit])

  useEffect(() => {
    if (agentImg == "null" || agentImg == null) {
      setAgentImg(defaultBotImg)
    }
  }, [agentImg])

  useEffect(() => {
    const handleActiveAgentEvent = (event) => {
      const eventData = event.detail; // Access the data sent with the event
      const { agentId } = eventData; // Extract the agentId from the event data
      setActiveAgentName('')
      setAgentImg('')
      setAgentConfigImg('')
      setAgentConfigName('')
      handleAgentConfigName(agentId);
      setCurrentTab('chat')
    };

    window.addEventListener('activeAgent', handleActiveAgentEvent);

    return () => {
      window.removeEventListener('activeAgent', handleActiveAgentEvent);
    };
  }, []);

  useEffect(() => {
    setActiveAgentName(agentConfigName);
  }, [agentConfigName, agentId]);

  useEffect(() => {
    setAgentImg(agentConfigImg);
  }, [agentConfigImg, agentId]);

  useEffect(() => {

    if (agentId) {
      const agentid = decodeURIComponent(decryptData(agentId));
      const parsedAgentID = parseInt(agentid, 10);
      if (!isNaN(parsedAgentID)) {
        getUserEditPermission(parsedAgentID)
        handleAgentConfigName(parsedAgentID);
        if (agentUpdate) {
          setAgentUpdate(false);
        }
      }
    }
  }, [agentId, agentUpdate]);

  if (!config.app?.header?.display) {
    return null;
  }

  const getUserRoleByAgent = async (activeAgentID: number) => {
    try {
      if (activeAgentID) {
        const response = await getUserRoleByAgentId(activeAgentID)
        if (response) {
          setUserRole(response.role)
        }
      }

    }
    catch (e) {
      console.error(e)
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const getUserEditPermission = async (agent_id: number) => {
    try {

      const response = await getUserAgentPermission(agent_id);

      if (response.length > 0) {
        setUserEditPermission(true);
      }
      else {
        setUserEditPermission(false)
      }
    } catch (error) {
      console.error(error);
    }
  }
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const activateFirstThread = async (agentId: number, userId: number, refresh: boolean) => {
    try {
      const response = await getAllChatThreads(agentId, userId);
      if (response.length > 0) {
        localStorage.setItem('activeThread', response[0].id.toString())
        getOneThread(parseInt(response[0].id.toString()));
        setUpdateChatlog(true);
        if (refresh) {
          setRefreshChatPage(true)
        }
        setThreads(response);
        divideThreadTimewise(response)
      }
      else {
        addThread(userId, userEmail)
      }
    } catch (error) {
      console.error(error);
    }
  }
  const addThread = async (user_id: number, userEmail: string) => {
    const agentID = decodeURIComponent(decryptData(agentId));
    const parsedAgentID = parseInt(agentID, 10);
    try {
      const response = await createThread(parsedAgentID, userEmail, user_id)
      if (response) {
        localStorage.setItem('activeThread', response[0].id.toString())
        getOneThread(parseInt(response[0].id.toString()));
        setUpdateChatlog(true);
        setThreads(response);
        divideThreadTimewise(response)
        setRefreshChatPage(true)
      }

    }
    catch (error) {
      console.error(error)
    }
  }
  const handleAgentConfigName = async (id: number) => {
    try {
      if (shareQueryParam === 'Allow-Edit-Only' || shareQueryParam === 'Allow-View-Only') {
        const response = await getOneAgentForMagicLink(id);
        if (response?.access !== 'Public') {
          navigate('/error/unauthorized-error/403')
        }
        else {
          setAgentConfigName(response.agent_name);
          setAgentImg(response.agent_image);
          setAccess(response.access);
          setCreatedBy(response.created_by)
          setAgentAlias(response.alias)
        }
      } else {
        const response = await getOneAgent(id);
        if (response) {
          setAgentConfigName(response.agent_name);
          setAgentImg(response.agent_image);
          setAccess(response.access);
          setCreatedBy(response.created_by)
          setAgentAlias(response.alias)
          if (loggedInUserEmail.length > 1 && response.access === 'Only me' && response.created_by !== 'System' && !((response.created_by === loggedInUserEmail) || isSuper)) {
            navigate('/error/unauthorized-error/403')
          }
        }
      }
    } catch (error) {
      console.error(error);
      navigate('/error/unauthorized-error/403')
    }
  };

  const handlePermission = () => {
    if ((tabParam === 'configure' || tabParam === 'knowledgeBase') && !isStaff && !isSuper && !userEditPermission && (createdBy !== loggedInUserEmail) && (createdBy !== 'System') && !(shareQueryParam === 'Allow-Edit-Only') && !(userRole === 'Owner')) {
      if (shareQueryParam === 'Allow-Edit-Only' || shareQueryParam === 'Allow-View-Only') {
        navigate(`/agent/${agentId}?tab=chat&share=${encodeURIComponent(encryptData(shareQueryParam))}`)
      } else {
        navigate(`/agent/${agentId}?tab=chat`);
      }

    }
    if (tabParam === 'evaluation' && !isSuper) {
      if (shareQueryParam === 'Allow-Edit-Only' || shareQueryParam === 'Allow-View-Only') {
        navigate(`/agent/${agentId}?tab=chat&share=${encodeURIComponent(encryptData(shareQueryParam))}`)
      } else {
        navigate(`/agent/${agentId}?tab=chat`)
      }
    }
  }
  const divideThreadTimewise = (threads: ChatThreads[]) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const dayBeforeYesterday = new Date(yesterday)

    dayBeforeYesterday.setDate(yesterday.getDate() - 1);

    const previous7Days = new Date(dayBeforeYesterday); // Start from day before yesterday
    previous7Days.setDate(previous7Days.getDate() - 7);

    const threadsToday = threads.filter(thread => {
      const threadDate = new Date(thread.created_on);
      return threadDate.toDateString() === today.toDateString();
    });
    setThreadsOfToday(threadsToday);

    const threadsYesterday = threads.filter(thread => {
      const threadDate = new Date(thread.created_on);
      return threadDate.toDateString() === yesterday.toDateString();
    });
    setThreadsOfYesterday(threadsYesterday);

    const threadsPrevious7Days = threads.filter(thread => {
      const threadDate = new Date(thread.created_on);
      return threadDate > previous7Days && threadDate < dayBeforeYesterday;
    });
    setThreadsOfPrev7Days(threadsPrevious7Days);

    const threadsOlder = threads.filter(thread => {
      const threadDate = new Date(thread.created_on);
      return threadDate < previous7Days;
    });
    setThreadsOlder(threadsOlder);
  }

  const getThreads = async (activeAgentID: number, userId: number) => {

    try {
      const response = await getAllChatThreads(activeAgentID, userId);
      if (response) {
        setThreads(response);
        divideThreadTimewise(response)
      }
    } catch (error) {
      // console.error(error);
    }
  };

  const DeleteThread = async (thread_id: number) => {
    const activeThread = localStorage.getItem('activeThread');
    try {
      const response = await deleteThread(thread_id);
      if (response) {
        setThreads(response);
        divideThreadTimewise(response)
        if (activeThread && (thread_id == parseInt(activeThread))) {
          setActiveThread(response[0]);
          localStorage.setItem('activeThread', response[0].id.toString());
          setUpdateChatlog(!updatechatlog)
          setRefreshChatPage(true)
        }

      }
    } catch (error) {
      console.error(error);
    }
  };

  const EditThread = async (thread_id: number, title: string, modified_by: string, oldName: string) => {
    const agentID = decodeURIComponent(decryptData(agentId));
    const activeAgentID = parseInt(agentID, 10);
    try {
      const response = await editThread(thread_id, modified_by, title);
      if (response) {
        getThreads(activeAgentID, parseInt(loggedInUser));
        if (thread_id == activeThread?.id) {
          getOneThread(thread_id);
        }

      }
    } catch (error) {
      console.error(error);
    }
    setIsEditing(false)
  };

  const activateThread = (thread: ChatThreads) => {
    if (thread) {
      setIsLoadingChat(true)
      localStorage.setItem('activeThread', thread.id.toString());
      setUpdateChatlog(!updatechatlog);
      setActiveThread(thread);
      setShow(false);
      setRefreshChatPage(true)
    }
  };

  const getOneThread = async (thread_id: number) => {
    try {
      const response = await getAChatThread(thread_id);
      if (response) {
        setActiveThread(response);
      }
    } catch (e) {
      console.error(e);
    }
  };
  const handleThreadNameChange = (event) => {
    event.stopPropagation();
    setThreadName(event.target.value);
  }
  const handleEdit = (event: any, thread_id: number, oldName: string) => {
    event.stopPropagation();
    handlePopoverClose()
    setThreadName(oldName)
    setIsEditing(true)
    setThreadToBeEdited(thread_id)
  }
  const handleTickClick = (event, thread_id: number, newTitle: string, modified_by: string, oldTitle: string) => {
    // event.stopPropagation();
    if (threadName == '') {
      setWarning(true)
    }
    else {
      setIsEditing(false)
      setWarning(false)
      EditThread(thread_id, newTitle, modified_by, oldTitle)
      setThreadName('')
    }

  }
  const handleModalOpen = () => {
    setShow(!show)
    setIsEditing(false)
    setWarning(false)
  }
  const handleDelete = (event, thread_id: number) => {
    event.stopPropagation();
    handlePopoverClose()
    setIsDelete(true)
    setThreadToBeDeleted(thread_id)
  }
  const handleCancelDelete = (event) => {
    event.stopPropagation();
    setIsDelete(false)
  }
  const handleConfirmDelete = (event, thread_id: number) => {
    event.stopPropagation();
    DeleteThread(thread_id)
    setIsDelete(false)
  }
  const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>, id: number) => {
    event.stopPropagation();
    handleClick(event);
    setThreadToShowActionsFor(id);
  };
  const handleActiveTab = (item: string) => {
    // const shareParams =queryParams.get('share')
    if (shareQueryParam === 'Allow-Edit-Only' || shareQueryParam === 'Allow-View-Only') {
      navigate(`/agent/${agentId}?tab=${item}&share=${encodeURIComponent(encryptData(shareQueryParam))}`)
    } else {
      navigate(`/agent/${agentId}?tab=${item}`)
    }

  }
  const CreateStarredAgent = async () => {
    const agentID = decodeURIComponent(decryptData(agentId));
    const parsedAgentID = parseInt(agentID, 10);

    try {
      if (loggedInUserId) {
        const response = await createStarredAgent(loggedInUserId, parsedAgentID);

        setIsStarred(true)
      }
    } catch (error) {
      setIsStarred(false)
      console.error(error);
    }
  }
  const DeleteStarredAgent = async () => {
    const agentID = decodeURIComponent(decryptData(agentId));
    const parsedAgentID = parseInt(agentID, 10);

    try {
      if (loggedInUserId) {
        const response = await deleteStarredAgent(loggedInUserId, parsedAgentID);
        if (response)
          setIsStarred(false)

      }
    } catch (error) {
      setIsStarred(true)
      console.error(error);
    }
  }
  const getStarredAgent = async (userId: number) => {
    const agentID = decodeURIComponent(decryptData(agentId));
    const parsedAgentID = parseInt(agentID, 10);
    try {
      const response = await getOneStarredAgent(userId, parsedAgentID);
      if (response.length > 0) {
        setIsStarred(true)
      }
      else {
        setIsStarred(false)
      }
    } catch (error) {
      console.error(error);
    }
  }
  const handleEmailClick = () => {
    navigator.clipboard.writeText(agentAlias!);
    handleShowToast();

  };
  const handleShowToast = () => {
    toast.success('Email Alias copied!', {
      style: {
        fontSize: '14px',

      },
    });
  };

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div id='kt_app_header' className='app-header d-flex flex-column ' style={{ marginLeft: '0rem' }}  >
      <div
        id='kt_app_header_container'
        className={clsx(
          'app-container flex-lg-grow-1',
          classes.headerContainer.join(' '),
          config.app?.header?.default?.containerClass
        )}
      >
        {shareQueryParam !== "Allow-Embed-Only" && config.app.sidebar?.display && (
          <>
            <div
              className='d-flex align-items-center d-lg-none ms-n2 me-2'
              title='Show sidebar menu'
              style={{ marginTop: '0.3rem' }}
            >
              <div
                className='btn btn-icon btn-active-color-primary w-35px h-35px'
                id='kt_app_sidebar_mobile_toggle'
              >
                <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className=' svg-icon-1' />
              </div>
              <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
              </div>
            </div>
          </>
        )}

        {!config.app.sidebar?.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15'>
            {config.layoutType !== 'dark-header' ? (
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/default-dark.svg')}
                className='h-20px h-lg-30px app-sidebar-logo-default'
              />
            ) : (
              <>
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/default.svg')}
                  className='h-20px h-lg-30px app-sidebar-logo-default theme-light-show'
                />
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/default-dark.svg')}
                  className='h-20px h-lg-30px app-sidebar-logo-default theme-dark-show'
                />
              </>
            )}
          </div>
        )}

        <div
          id='kt_app_header_wrapper'
          className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'
        >
          {config.app.header.default?.content === 'menu' &&
            config.app.header.default.menu?.display && (
              <div
                className='app-header-menu app-header-mobile-drawer align-items-stretch'
                data-kt-drawer='true'
                data-kt-drawer-name='app-header-menu'
                data-kt-drawer-activate='{default: true, lg: false}'
                data-kt-drawer-overlay='true'
                data-kt-drawer-width='225px'
                data-kt-drawer-direction='end'
                data-kt-drawer-toggle='#kt_app_header_menu_toggle'
                data-kt-swapper='true'
                data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
                data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
              >
                <Header />
              </div>
            )}

          <div className={styles.headerWrapper}>
            <div className={styles.left}>
              {location.pathname.startsWith('/agent/') ? (
                <div className={styles.imageParent}>

                  <img style={{ marginLeft: "1%" }} className={styles.headerAgentImg} src={agentImg ? agentImg : defaultBotImg} width={50} height={50}></img>


                </div>) : null
              }

              <div >
                {location.pathname.startsWith('/agent/') ? (

                  <div className={styles.agentName}>
                    <div ref={dropdownRef}>
                      {/*threads dropdown begin*/}
                      <>
                        <Dropdown>
                          <div 
                            ref={dropdownRef}
                            className={styles.activeAgentNameParent}
                            id="dropdown-custom-components"

                          >
                            <span onClick={tab === 'chat' ? handleModalOpen : undefined} className={styles.activeAgentName}>{activeAgentName}</span>
                            {
                              tab === 'chat' &&
                              <span onClick={handleModalOpen} className={styles.activeAgentName}>
                                <>/ {activeThread?.title}</>
                              </span>}
                            {
                              tab === 'chat' && <KeyboardArrowDownSharpIcon onClick={handleModalOpen} className={styles.downArrow} />
                            }
                            {
                              isStarred ?
                                <Tooltip title='Unstar agent'><div className={`${styles.star}`} onClick={DeleteStarredAgent}><Star style={{ cursor: 'pointer' }} color="#ed6c02" size={16} weight="fill" /></div></Tooltip> :
                                <Tooltip title='Star agent'><div className={`${styles.star}`} onClick={CreateStarredAgent}><Star style={{ cursor: 'pointer' }} color="#ed6c02" size={16} weight="light" /></div></Tooltip>
                            }

                          </div>
                          {
                            show &&
                            <div className={styles.modalThreads} style={{ backgroundColor: activeTheme == 'dark' ? '#1e1e2d' : 'white',  width:"24rem" }} >
                              <div style={{width:"23rem",  display:"flex", alignItems: "center"}} >
                                <Form.Control
                                  autoFocus
                                  className=" my-2 mx-auto"
                                  style={{ width: '22.8rem' }}
                                  placeholder="Type to filter..."
                                  onChange={(e) => setValue(e.target.value)}
                                  value={value}
                                />

                              </div>


                              {threadsOfToday.length > 0 &&
                                <div style={{ color: activeTheme == 'dark' ? '#b7b6b6' : 'black' }} className={styles.threadTime}>Today</div>}
                              {threadsOfToday
                                .filter((item) => {
                                  // Filter threads based on search query
                                  return item.title.toLowerCase().includes(value.toLowerCase());
                                })
                                .map((thread, index) => (
                                  <>
                                    {
                                      isEditing && (thread.id === threadToBeEdited) ?
                                        <div  ref={dropdownRef} key={index} className='d-flex'>
                                          <div >
                                            <TextField
                                              ref={dropdownRef}
                                              required
                                              hiddenLabel
                                              id="standard-required"
                                              value={threadName}
                                              onChange={(e) => handleThreadNameChange(e)}
                                              // onBlur={() => EditThread(thread.id, thread.modified_by, threadName, thread.title)}
                                              // onKeyDown={handleKeyDown}
                                              InputProps={{
                                                style: {
                                                  width: '20.1rem',
                                                  color: activeTheme === 'dark' ? 'white' : 'black',

                                                },
                                              }}
                                              autoFocus
                                              variant="standard"
                                              sx={{
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                  WebkitTextFillColor: activeTheme === 'dark' ? 'white' : 'black',
                                                },
                                                "& .MuiInputBase-root": {
                                                  width: 180,
                                                },
                                                "& .MuiInput-underline:after": {
                                                  borderBottomColor: "#20808d"
                                                },
                                              }}
                                            />
                                            {
                                              warning && <p style={{ color: 'red', fontSize: '0.9rem' }}>Please enter thread name</p>
                                            }
                                          </div>
                                          <IconButton onClick={(e) => handleTickClick(e, thread.id, threadName, thread.modified_by, thread.title)}>
                                            <CheckSharpIcon />
                                          </IconButton>
                                        </div> : isdelete && (thread.id === threadToBeDeleted) ? <div className='d-flex align-items-center ps-3'>
                                          <div style={{ width: '20.1rem' }}>Are you sure?</div>
                                          <IconButton onClick={(e) => handleConfirmDelete(e, thread.id)}>
                                            <CheckSharpIcon />
                                          </IconButton>
                                          <IconButton onClick={(e) => handleCancelDelete(e)}>
                                            <CloseSharpIcon />
                                          </IconButton>

                                        </div> : <>
                                          <div
                                            style={{width:"20.4rem", backgroundColor: thread.id == activeThread?.id ? activeTheme == 'dark' ? '#343541' : 'rgb(224 243 245)' : '', height: '3.5rem', borderRadius: "0.5rem", padding: '0.4rem' }}
                                            key={index}
                                            className={styles.customDropdownItem}
                                          >
                                            <div style={{ width: '20.1rem', paddingLeft:"1%", }} onClick={() => activateThread(thread)}>
                                              {thread.title.length > 33 ? thread.title.slice(0, 34) + '...' : thread.title}
                                            </div>

                                            <IconButton onClick={(event) => handleMoreClick(event, thread.id)}>
                                              <MoreHorizSharpIcon style={{ color: activeTheme == 'dark' ? '#999999' : 'black' }} />
                                            </IconButton>
                                          </div> <Divider style={{ backgroundColor: 'grey' }}></Divider> </>
                                    }
                                    {(thread.id == threadToShowActionsFor) && <div>
                                      <Popover
                                        open={Boolean(anchorEl)}
                                        anchorEl={anchorEl}
                                        onClose={handlePopoverClose}
                                        anchorOrigin={{
                                          vertical: 'bottom',
                                          horizontal: 'right',
                                        }}

                                      >
                                        <MenuItem disabled={thread.title === 'New Thread'} onClick={(e) => handleEdit(e, thread.id, thread.title)}>
                                          <ModeEditSharpIcon />
                                          Edit
                                        </MenuItem>
                                        <MenuItem disabled={threads.length == 1 ? true : false} onClick={(e) => handleDelete(e, thread.id)}>
                                          <DeleteOutlineOutlinedIcon />
                                          Delete
                                        </MenuItem>
                                      </Popover>
                                    </div>}
                                  </>
                                ))}

                              {threadsOfToday.length > 0 && <div className='mt-11'></div>}

                              {threadsOfYesterday.length > 0 && <div style={{ color: activeTheme == 'dark' ? '#b7b6b6' : 'black' }} className={styles.threadTime}>Yesterday</div>}
                              {threadsOfYesterday
                                .filter((item) => {
                                  // Filter threads based on search query
                                  return item.title.toLowerCase().includes(value.toLowerCase());
                                })
                                .map((thread, index) => (
                                  <>
                                    {
                                      isEditing && (thread.id === threadToBeEdited) ?
                                        <div ref={dropdownRef} key={index} className='d-flex'>
                                          <div ref={dropdownRef}>
                                            <TextField
                                              required
                                              hiddenLabel
                                              id="standard-required"
                                              value={threadName}
                                              onChange={handleThreadNameChange}
                                              // onBlur={() => EditThread(thread.id, thread.modified_by, threadName, thread.title)}
                                              // onKeyDown={handleKeyDown}
                                              InputProps={{
                                                style: {
                                                  width: '20.1rem',
                                                  color: activeTheme === 'dark' ? 'white' : 'black',

                                                },
                                              }}
                                              autoFocus
                                              variant="standard"
                                              sx={{
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                  WebkitTextFillColor: activeTheme === 'dark' ? 'white' : 'black',
                                                },
                                                "& .MuiInputBase-root": {
                                                  width: 180,
                                                },
                                                "& .MuiInput-underline:after": {
                                                  borderBottomColor: "#20808d"
                                                },
                                              }}
                                            />
                                            {
                                              warning && <p style={{ color: 'red', fontSize: '0.9rem' }}>Please enter thread name</p>
                                            }
                                          </div>
                                          <IconButton onClick={(e) => handleTickClick(e, thread.id, threadName, thread.modified_by, thread.title)}>
                                            <CheckSharpIcon />
                                          </IconButton>
                                        </div> : isdelete && (thread.id === threadToBeDeleted) ? <div className='d-flex align-items-center ps-3'>
                                          <div style={{ width: '20.1rem' }}>Are you sure?</div>
                                          <IconButton onClick={(e) => handleConfirmDelete(e, thread.id)}>
                                            <CheckSharpIcon />
                                          </IconButton>
                                          <IconButton onClick={(e) => handleCancelDelete(e)}>
                                            <CloseSharpIcon />
                                          </IconButton>

                                        </div> : <>
                                          <div
                                            style={{ width:"20.4rem", backgroundColor: thread.id == activeThread?.id ? activeTheme == 'dark' ? '#343541' : 'rgb(224 243 245)' : '', height: '3.5rem', borderRadius: "0.5rem", padding: '0.4rem' }}
                                            key={index}
                                            className={styles.customDropdownItem}
                                          >
                                            <div style={{ width: '20.1rem', paddingLeft:"1%", }} onClick={() => activateThread(thread)}>
                                              {thread.title.length > 33 ? thread.title.slice(0, 34) + '...' : thread.title}
                                            </div>
                                            <Divider></Divider>

                                            <IconButton onClick={(event) => handleMoreClick(event, thread.id)}>
                                              <MoreHorizSharpIcon style={{ color: activeTheme == 'dark' ? '#999999' : 'black' }} />
                                            </IconButton>
                                          </div>
                                          <Divider style={{ backgroundColor: 'grey' }}></Divider>
                                        </>
                                    }
                                    {(thread.id == threadToShowActionsFor) && <div>
                                      <Popover
                                        open={Boolean(anchorEl)}
                                        anchorEl={anchorEl}
                                        onClose={handlePopoverClose}
                                        anchorOrigin={{
                                          vertical: 'bottom',
                                          horizontal: 'right',
                                        }}

                                      >
                                        <MenuItem disabled={thread.title === 'New Thread'} onClick={(e) => handleEdit(e, thread.id, thread.title)}>
                                          <ModeEditSharpIcon />
                                          Edit
                                        </MenuItem>
                                        <MenuItem disabled={threads.length == 1 ? true : false} onClick={(e) => handleDelete(e, thread.id)}>
                                          <DeleteOutlineOutlinedIcon />
                                          Delete
                                        </MenuItem>
                                      </Popover>
                                    </div>}
                                  </>
                                ))}
                              {threadsOfYesterday.length > 0 && <div className='mt-11'></div>}
                              {threadsOfPrev7Days.length > 0 && <div style={{ color: activeTheme == 'dark' ? '#b7b6b6' : 'black' }} className={styles.threadTime}>Previous 7 Days</div>}
                              {threadsOfPrev7Days
                                .filter((item) => {
                                  // Filter threads based on search query
                                  return item.title.toLowerCase().includes(value.toLowerCase());
                                })
                                .map((thread, index) => (
                                  <>
                                    {
                                      isEditing && (thread.id === threadToBeEdited) ?
                                        <div key={index} className='d-flex'>
                                          <div > 
                                            <TextField
                                              required
                                              hiddenLabel
                                              id="standard-required"
                                              value={threadName}
                                              onChange={handleThreadNameChange}
                                              // onBlur={() => EditThread(thread.id, thread.modified_by, threadName, thread.title)}
                                              // onKeyDown={handleKeyDown}
                                              InputProps={{
                                                style: {
                                                  width: '20.1rem',
                                                  color: activeTheme === 'dark' ? 'white' : 'black',

                                                },
                                              }}
                                              autoFocus
                                              variant="standard"
                                              sx={{
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                  WebkitTextFillColor: activeTheme === 'dark' ? 'white' : 'black',
                                                },
                                                "& .MuiInputBase-root": {
                                                  width: 180,
                                                },
                                                "& .MuiInput-underline:after": {
                                                  borderBottomColor: "#20808d"
                                                },
                                              }}
                                            />
                                            {
                                              warning && <p style={{ color: 'red', fontSize: '0.9rem' }}>Please enter thread name</p>
                                            }
                                          </div>
                                          <IconButton onClick={(e) => handleTickClick(e, thread.id, threadName, thread.modified_by, thread.title)}>
                                            <CheckSharpIcon />
                                          </IconButton>
                                        </div> : isdelete && (thread.id === threadToBeDeleted) ? <div className='d-flex align-items-center ps-3'>
                                          <div style={{ width: '20.1rem' }}>Are you sure?</div>
                                          <IconButton onClick={(e) => handleConfirmDelete(e, thread.id)}>
                                            <CheckSharpIcon />
                                          </IconButton>
                                          <IconButton onClick={(e) => handleCancelDelete(e)}>
                                            <CloseSharpIcon />
                                          </IconButton>

                                        </div> : <>
                                          <div
                                            style={{ width:"20.4rem", backgroundColor: thread.id == activeThread?.id ? activeTheme == 'dark' ? '#343541' : 'rgb(224 243 245)' : '', height: '3.5rem', borderRadius: "0.5rem", padding: '0.4rem' }}
                                            key={index}
                                            className={styles.customDropdownItem}
                                          >
                                            <div style={{ width: '20.1rem', paddingLeft:"1%", }} onClick={() => activateThread(thread)}>
                                              {thread.title.length > 33 ? thread.title.slice(0, 34) + '...' : thread.title}

                                            </div>

                                            <IconButton onClick={(event) => handleMoreClick(event, thread.id)}>
                                              <MoreHorizSharpIcon style={{ color: activeTheme == 'dark' ? '#999999' : 'black' }} />
                                            </IconButton>
                                          </div>            <Divider style={{ backgroundColor: 'grey' }} /></>
                                    }
                                    {(thread.id == threadToShowActionsFor) && <div>
                                      <Popover
                                        open={Boolean(anchorEl)}
                                        anchorEl={anchorEl}
                                        onClose={handlePopoverClose}
                                        anchorOrigin={{
                                          vertical: 'bottom',
                                          horizontal: 'right',
                                        }}

                                      >
                                        <MenuItem disabled={thread.title === 'New Thread'} onClick={(e) => handleEdit(e, thread.id, thread.title)}>
                                          <ModeEditSharpIcon />
                                          Edit
                                        </MenuItem>
                                        <MenuItem disabled={threads.length == 1 ? true : false} onClick={(e) => handleDelete(e, thread.id)}>
                                          <DeleteOutlineOutlinedIcon />
                                          Delete
                                        </MenuItem>
                                      </Popover>
                                    </div>}
                                  </>
                                ))}
                              {threadsOfPrev7Days.length > 0 && <div className='mt-11'></div>}
                              {threadsOlder.length > 0 && <div style={{ color: activeTheme == 'dark' ? '#b7b6b6' : 'black' }} className={styles.threadTime}>Older</div>}
                              {threadsOlder
                                .filter((item) => {
                                  // Filter threads based on search query
                                  return item.title.toLowerCase().includes(value.toLowerCase());
                                })
                                .map((thread, index) => (
                                  <>
                                    {
                                      isEditing && (thread.id === threadToBeEdited) ?
                                        <div  key={index} className='d-flex'>
                                          <div>
                                            <TextField
                                              required
                                              hiddenLabel
                                              id="standard-required"
                                              value={threadName}
                                              onChange={handleThreadNameChange}
                                              // onFocus={(e) => handleFocus(e)}
                                              // onBlur={() => EditThread(thread.id, thread.modified_by, threadName, thread.title)}
                                              // onKeyDown={handleKeyDown}
                                              InputProps={{
                                                style: {
                                                  width: '20.1rem',
                                                  color: activeTheme === 'dark' ? 'white' : 'black',

                                                },
                                              }}
                                              autoFocus
                                              variant="standard"
                                              sx={{
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                  WebkitTextFillColor: activeTheme === 'dark' ? 'white' : 'black',
                                                },
                                                "& .MuiInputBase-root": {
                                                  width: 180,
                                                },
                                                "& .MuiInput-underline:after": {
                                                  borderBottomColor: "#20808d"
                                                },
                                              }}
                                            />
                                            {
                                              warning && <p style={{ color: 'red', fontSize: '0.9rem' }}>Please enter thread name</p>
                                            }
                                          </div>
                                          <IconButton onClick={(e) => handleTickClick(e, thread.id, threadName, thread.modified_by, thread.title)}>
                                            <CheckSharpIcon />
                                          </IconButton>
                                        </div> : isdelete && (thread.id === threadToBeDeleted) ? <div className='d-flex align-items-center ps-3'>
                                          <div style={{ width: '20.1rem' }}>Are you sure?</div>
                                          <IconButton onClick={(e) => handleConfirmDelete(e, thread.id)}>
                                            <CheckSharpIcon />
                                          </IconButton>
                                          <IconButton onClick={(e) => handleCancelDelete(e)}>
                                            <CloseSharpIcon />
                                          </IconButton>

                                        </div> :
                                          <>
                                            <div
                                              style={{width:"20.4rem", backgroundColor: thread.id == activeThread?.id ? activeTheme == 'dark' ? '#343541' : 'rgb(224 243 245)' : '', height: '3.5rem', borderRadius: "0.5rem", padding: '0.4rem' }}
                                              key={index}
                                              className={styles.customDropdownItem}
                                            >
                                              <div style={{ width: '20.1rem', paddingLeft:"1%", }} onClick={() => activateThread(thread)}>
                                                {thread.title.length > 22 ? thread.title.slice(0, 34) + '...' : thread.title}
                                              </div>

                                              <IconButton onClick={(event) => handleMoreClick(event, thread.id)}>
                                                <MoreHorizSharpIcon style={{ color: activeTheme == 'dark' ? '#999999' : 'black' }} />
                                              </IconButton>
                                            </div>
                                            <Divider style={{ backgroundColor: 'grey' }}></Divider>
                                          </>
                                    }
                                    {(thread.id == threadToShowActionsFor) && <div>
                                      <Popover
                                        open={Boolean(anchorEl)}
                                        anchorEl={anchorEl}
                                        onClose={handlePopoverClose}
                                        anchorOrigin={{
                                          vertical: 'bottom',
                                          horizontal: 'right',
                                        }}

                                      >
                                        <MenuItem disabled={thread.title === 'New Thread'} onClick={(e) => handleEdit(e, thread.id, thread.title)}>
                                          <ModeEditSharpIcon />
                                          Edit
                                        </MenuItem>
                                        <MenuItem disabled={threads.length == 1 ? true : false} onClick={(e) => handleDelete(e, thread.id)}>
                                          <DeleteOutlineOutlinedIcon />
                                          Delete
                                        </MenuItem>
                                      </Popover>
                                    </div>}
                                  </>
                                ))}
                            </div>
                          }

                        </Dropdown>
                      </>
                    </div>
                    <div className={styles.tabs}  >

                      <div className={` pb-1 ${styles.item} ${tab === 'chat' ? styles.activeTab : ''}`} onClick={() => handleActiveTab('chat')}><ChatCircleText size={19} weight="light" />
                        <span>Chat</span> </div>
                    
                      {
                        (shareQueryParam === 'Allow-Edit-Only' || userEditPermission === true || createdBy === loggedInUserEmail || isSuper || isStaff || createdBy === 'System' || userRole === 'Owner') &&
                        <div className={`pb-1 ${styles.item} ${tab === 'knowledgeBase' ? styles.activeTab : ''}`} onClick={() => handleActiveTab('knowledgeBase')}><FolderNotch size={19} weight="light" /><span>Knowledge Base</span> </div>
                      }
                        {createdBy !== 'System' &&
                        (shareQueryParam === 'Allow-Edit-Only' || userEditPermission === true || createdBy === loggedInUserEmail || isSuper || isStaff || userRole === 'Owner') &&
                        <div className={`pb-1 ${styles.item} ${tab === 'configure' ? styles.activeTab : ''}`} onClick={() => handleActiveTab('configure')}><GearSix size={19} weight="light" /><span>Edit Agent</span> </div>
                      }
                      {createdBy !== 'System' &&
                        isSuper && <div className={` pb-1 ${styles.item} ${tab === 'evaluation' ? styles.activeTab : ''}`} onClick={() => handleActiveTab('evaluation')}><Gauge size={20} weight="light" /><span>Evaluation Testing</span> </div>
                      }
                    </div>
                    <div className={styles.nav} >

                      <Nav >
                        <NavDropdown
                          id="nav-dropdown-dark-example"
                          style={{ padding: 0 }}
                          title={<span style={{ fontWeight: 'bolder', fontSize: '14px', padding: '0', marginBottom: '0.5rem' }}>{
                            tab === 'chat' ? 'Chat' : tab === 'configure' ? 'Edit Agent' : tab === 'knowledgeBase' ? 'Knowledge base' : 'Evaluation testing'
                          }</span>}
                          menuVariant="dark"

                        >
                          <NavDropdown.Item className={`pb-1  ${tab === 'chat' ? styles.activeTabSm : ''}`} onClick={() => handleActiveTab('chat')}>Chat</NavDropdown.Item>
                          {createdBy !== 'System' &&
                            (shareQueryParam === 'Allow-Edit-Only' || userEditPermission === true || createdBy === loggedInUserEmail || isSuper || userRole === 'Owner' || isStaff) &&
                            <NavDropdown.Item className={`pb-1  ${tab === 'configure' ? styles.activeTabSm : ''}`} onClick={() => handleActiveTab('configure')}> Edit agent</NavDropdown.Item>
                          }

                          {
                            (shareQueryParam === 'Allow-Edit-Only' || userEditPermission === true || createdBy === loggedInUserEmail || isSuper || userRole === 'Owner' || createdBy === 'System' || isStaff) &&
                            <NavDropdown.Item onClick={() => handleActiveTab('knowledgeBase')} className={`pb-1 ${tab === 'knowledgeBase' ? styles.activeTabSm : ''}`}>Knowledge base</NavDropdown.Item>
                          }
                          {createdBy !== 'System' &&
                            isSuper && <NavDropdown.Item onClick={() => handleActiveTab('evaluation')} className={`pb-1 ${tab === 'evaluation' ? styles.activeTabSm : ''}`}>Evaluation testing</NavDropdown.Item>

                          }


                        </NavDropdown>
                      </Nav>


                    </div>


                  </div>

                ) : null}
              </div >
            </div>

            <div className={styles.navbar}>
              <MetronicNavbar setCurrentTab={setCurrentTab} agentUpdateAfterDelete={agentUpdateAfterDelete} setAgentUpdate={setAgentUpdate} agentUpdateAfterEdit={agentUpdateAfterEdit} isEmbedding={isEmbedding} agentAccess={access} />
            </div>

          </div>

        </div>
      </div>


    </div>
  );
}
